import { useLazyQuery } from "@apollo/client";
import { Box, Skeleton, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import parse from "html-react-parser";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { selectQRCode, setQrCode } from "slices/accessSlice";

import { DrawerToggleIndicator } from "components/primitives/DrawerToggleIndicator";
import { HeaderText } from "components/primitives/HeaderText";
import { SwipeableArea } from "components/primitives/SwipeableArea";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";

import { getFridgeId } from "../../utils/browser/localStorage";
import { GET_QR_CODE_QUERY } from "../../utils/gqlOperations";
import { PlaceCardArrow } from "../PlaceCardArrow";

type OrderStep1Props = {
	open: boolean;
	toggleDrawer: (newState: boolean) => void;
};

const QR_CODE_IPAD_SIZE_SMALL = 180;
const QR_CODE_IPAD_SIZE_LARGE = 300;
const QR_CODE_SAMSUNG_SIZE_SMALL = 140;
const QR_CODE_SAMSUNG_SIZE_LARGE = 260;

export const OrderStep1 = ({ open, toggleDrawer }: OrderStep1Props) => {
	const { t } = useTranslation();
	const qrCode = useAppSelector(selectQRCode);
	const dispatch = useAppDispatch();
	const swipeableAreaRef = useRef<HTMLDivElement>();
	const matches = useMediaQuery("(min-width:1000px)");
	const [getQrCodeQuery, { data: qrCodeData }] = useLazyQuery(GET_QR_CODE_QUERY);
	const QR_CODE_SIZE_OPEN = matches ? QR_CODE_IPAD_SIZE_LARGE : QR_CODE_SAMSUNG_SIZE_LARGE;
	const QR_CODE_SIZE_CLOSED = matches ? QR_CODE_IPAD_SIZE_SMALL : QR_CODE_SAMSUNG_SIZE_SMALL;

	const { trackOrderStep1Screen } = useMixpanelTrackers();

	useEffect(() => {
		trackOrderStep1Screen();
	}, []);

	const handleToggleDrawer = () => {
		toggleDrawer(!open);
	};

	useEffect(() => {
		if (!qrCode) {
			Sentry.captureException(new Error("Missing QR code"));
			getQrCodeQuery({
				variables: {
					id: getFridgeId(),
				},
				fetchPolicy: "network-only",
			}).catch((e) => {
				Sentry.captureException(e);
			});
		}
	}, [qrCode]);

	useEffect(() => {
		if (qrCodeData?.error) {
			Sentry.captureException(new Error("Could not fetch new QR code"));
		}
		if (qrCodeData && qrCodeData.fridge.access.qrCode.code) {
			dispatch(setQrCode(qrCodeData.fridge.access.qrCode.code));
		}
	}, [qrCodeData]);

	return (
		<>
			<StyledOrderStep1SwipeArea
				open={open}
				orderstep={1}
				sx={{
					display: "flex",
					transition: `all 225ms ${open ? "ease-out" : "ease-out"}`,
				}}
				className={"swipeable-area"}
				ref={swipeableAreaRef}
			>
				<DrawerToggleIndicator open={open} toggleDrawer={toggleDrawer} />
				<Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
					<StyledPlaceCardBox>
						<HeaderText variant={"cta"}>{t("order.step1.placeCard")}</HeaderText>
						<PlaceCardArrow isExpanded={open} />
					</StyledPlaceCardBox>
					<StyledQrCodeBox>
						<HeaderText variant={"cta"} align={"right"}>
							{parse(t("order.step1.scanCode"))}
						</HeaderText>
						{qrCode ? (
							<Box
								onClick={() => handleToggleDrawer()}
								className={"test box"}
								sx={{
									marginTop: "8px",
								}}
							>
								<img
									src={qrCode}
									alt={"QR code for access"}
									width={open ? QR_CODE_SIZE_OPEN : QR_CODE_SIZE_CLOSED}
									height={open ? QR_CODE_SIZE_OPEN : QR_CODE_SIZE_CLOSED}
									style={{
										transition: "all 0.5s ease",
										borderRadius: "12px",
									}}
								/>
							</Box>
						) : (
							<Skeleton
								width={open ? QR_CODE_SIZE_OPEN : QR_CODE_SIZE_CLOSED}
								height={open ? QR_CODE_SIZE_OPEN : QR_CODE_SIZE_CLOSED}
								variant={"rectangular"}
								role={"progressbar"}
							/>
						)}
					</StyledQrCodeBox>
				</Box>
			</StyledOrderStep1SwipeArea>
		</>
	);
};

const StyledPlaceCardBox = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "50%",

	"& svg": {
		marginTop: theme.spacing(1),
	},
}));

const StyledQrCodeBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-end",
}));

const StyledOrderStep1SwipeArea = styled(SwipeableArea, {
	shouldForwardProp: (prop) => prop !== "open",
})(() => ({
	backdropFilter: "blur(10px)",
}));
