import React, { FC } from "react";

interface ArrowProps {
	isExpanded: boolean;
}

export const PlaceCardArrow: FC<ArrowProps> = ({ isExpanded }) => {
	const thirdPathD = isExpanded
		? "M356.569 157V59.5686H362.569V137H356.569Z"
		: "M356.569 64.5686V59.5686H362.569V64.5686H356.569Z";
	const fourthPathD = isExpanded
		? "M356.569 191.757L356.569 137H362.569L362.569 191.757L376.539 177.787C377.711 176.615 379.61 176.615 380.782 177.787C381.953 178.958 381.953 180.858 380.782 182.029L361.69 201.121C360.518 202.293 358.619 202.293 357.447 201.121L338.355 182.029C337.184 180.858 337.184 178.958 338.355 177.787C339.527 176.615 341.427 176.615 342.598 177.787L356.569 191.757Z"
		: "M356.569 94.757L356.569 59.5686H362.569L362.569 94.757L376.539 80.787C377.711 79.615 379.61 79.615 380.782 80.787C381.953 81.958 381.953 83.858 380.782 85.029L361.69 104.121C360.518 105.293 358.619 105.293 357.447 104.121L338.355 85.029C337.184 83.858 337.184 81.958 338.355 80.787C339.527 79.615 341.427 79.615 342.598 80.787L356.569 94.757Z";

	return (
		<svg
			width="382"
			height="212"
			viewBox="0 0 382 212"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M329 6H0V0H330V6Z" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M356.569 27.0001C356.569 15.4021 347.167 5.99997 335.569 6L329 6.00007L329 7.18183e-05L335.569 0C350.48 -3.37174e-05 362.569 12.0884 362.569 27.0001L362.569 59.5686L356.569 59.5686L356.569 27.0001Z"
				fill="white"
			/>
			<path fillRule="evenodd" clipRule="evenodd" d={thirdPathD} fill="white" />
			<path fillRule="evenodd" clipRule="evenodd" d={fourthPathD} fill="white" />
		</svg>
	);
};
