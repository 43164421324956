import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import cs from "assets/locales/cs.json";
import en from "assets/locales/en.json";

use(initReactI18next).init({
	lng: "cs", // if you're using a language detector, do not define the lng option
	debug: true,
	resources: {
		cs: {
			translation: cs,
		},
		en: {
			translation: en,
		},
	},

	// if you see an error like: "Argument of type 'DefaultTFuncReturn'
	// is not assignable to parameter of type xyz"
	// set returnNull to false (and also in the i18next.d.ts options)
	// returnNull: false,
});
