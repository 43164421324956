import { useEffect, useState } from "react";
import { useAudioPlayer } from "react-use-audio-player";

import { Maybe, TransactionType } from "types";

import { useCountdown } from "./timers";

// When order type is refill, we delay the initial warning by 300s (5 minutes) we have to set the
// delay to 400 so the first warning is triggered when countdown reaches 100s
const REFILL_DOOR_WARNING_DELAY = 400;

export const useOpenDoorAlarm = (orderType?: Maybe<TransactionType>) => {
	const warningDelay = parseInt(import.meta.env.VITE_TOTAL_ALARM_TIMEOUT);
	const playSoundParamFromUrl = new URLSearchParams(window.location.search).get("playSound");

	const isRefill = orderType === TransactionType.Refill;

	const initialTimer = isRefill ? REFILL_DOOR_WARNING_DELAY : warningDelay;
	const { countdown, resetCountdown, startCountdown } = useCountdown(initialTimer);
	const [displayOpenedDoorWarning, setDisplayOpenedDoorWarning] = useState<boolean>(false);

	const { load: alarm1Load, play: alarm1Play, stop: alarm1Stop } = useAudioPlayer();
	const { load: alarm2Load, play: alarm2Play, stop: alarm2Stop } = useAudioPlayer();
	const { load: alarm3Load, play: alarm3Play, stop: alarm3Stop } = useAudioPlayer();

	const resetAlarmState = () => {
		alarm1Stop();
		alarm2Stop();
		alarm3Stop();
		if (displayOpenedDoorWarning) {
			setDisplayOpenedDoorWarning(false);
		}
	};

	const handleResetCountdown = () => {
		resetCountdown(true);
		setDisplayOpenedDoorWarning(false);
		resetAlarmState();
	};

	useEffect(() => {
		alarm1Load(import.meta.env.VITE_ALARM_SOUND_1_URL, {
			loop: true,
		});
		alarm2Load(import.meta.env.VITE_ALARM_SOUND_2_URL, {
			loop: true,
		});
		alarm3Load(import.meta.env.VITE_ALARM_SOUND_3_URL, {
			loop: true,
		});
		startCountdown(true);
		return () => {
			resetAlarmState();
			startCountdown(false);
		};
	}, []);

	useEffect(() => {
		if (countdown === parseInt(import.meta.env.VITE_DISPLAY_WARNING_TIMEOUT)) {
			setDisplayOpenedDoorWarning(true);
		} else if (playSoundParamFromUrl && playSoundParamFromUrl === "true") {
			if (countdown === parseInt(import.meta.env.VITE_FIRST_ALARM_TIMEOUT)) {
				alarm1Play();
			} else if (countdown === parseInt(import.meta.env.VITE_SECOND_ALARM_TIMEOUT)) {
				alarm1Stop();
				alarm2Play();
			} else if (countdown === parseInt(import.meta.env.VITE_THIRD_ALARM_TIMEOUT)) {
				alarm2Stop();
				alarm3Play();
			}
		}
	}, [countdown]);

	return {
		resetCountdown: () => handleResetCountdown(),
		displayOpenedDoorWarning,
	};
};
