import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getBackgroundColor } from "utils/utils";

export type BlurredBackgroundProps = {
	orderStep: number;
	open: boolean;
	isMobilePayment?: boolean;
};

export const BlurredBackground = styled(Box, {
	shouldForwardProp: (prop) =>
		prop !== "orderStep" && prop !== "open" && prop !== "isMobilePayment",
})<BoxProps & BlurredBackgroundProps>(({ theme, orderStep }) => ({
	position: "absolute",
	top: 0,
	left: 0,
	zIndex: -1,
	minWidth: "100%",
	minHeight: "100%",
	backgroundColor: orderStep !== 1 ? getBackgroundColor(orderStep, theme) : "unset",
	backdropFilter: "blur(20px)",
	borderRadius: "24px 24px 0 0",
}));
