import { RefObject } from "react";
import {
	filterAvailableDishTypeIds,
	handleDispatchHighlightedDishTypeId,
} from "utils/mealListUtils";

import { useAppContext } from "contexts";
import { LightingModeType } from "types";
import { LocalDishType } from "types/mainTypes";

import { useFilters } from "./useFilters";
import { useFridgeLights } from "./useFridgeLights";

interface useFridgeLightingHandlingReturn {
	handleOpen: (dishTypeId: string, ref?: RefObject<HTMLDivElement>) => void;
	closeOpenedDishTypeDetail: () => void;
	handleLightsStateWhenFilterIsActive: () => void;
	openDishTypeDetail: (dishTypeId: string) => void;
}

/**
 * Manages the events related to the use of meal list.
 * @param {string[]} availableDishTypeIds - The list of available dish type IDs.
 * @param {LocalDishType[]} fridgeDishes - The list of local dish types.
 * @return {useFridgeLightingHandlingReturn} - An object that contains event handling functions.
 */
const useMealListEvents = (
	availableDishTypeIds: string[],
	fridgeDishes: LocalDishType[],
): useFridgeLightingHandlingReturn => {
	const { allergens } = useFilters();

	const { openDetailDishTypeId, dispatch } = useAppContext();

	const { toggleLight, toggleFridgeLightingMode, highlightFilteredDishes } = useFridgeLights();
	const closeOpenedDishTypeDetail = () => {
		handleDispatchHighlightedDishTypeId(null, dispatch);
	};

	const openDishTypeDetail = (dishTypeId: string) => {
		handleDispatchHighlightedDishTypeId(dishTypeId, dispatch);
	};

	const scrollIntoView = (ref: RefObject<HTMLDivElement>) => {
		setTimeout(() => {
			ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
		}, 300);
	};

	const handleOpen = (dishTypeId: string, ref?: RefObject<HTMLDivElement>) => {
		if (ref) {
			scrollIntoView(ref);
		}
		if (openDetailDishTypeId === dishTypeId) {
			closeOpenedDishTypeDetail();
			return;
		} else {
			openDishTypeDetail(dishTypeId);
		}
	};

	const handleLightsStateWhenFilterIsActive = () => {
		const filteredDishTypesIds = filterAvailableDishTypeIds(
			fridgeDishes,
			availableDishTypeIds,
			allergens,
		);
		// We close currently opened dish type detail
		if (openDetailDishTypeId) {
			closeOpenedDishTypeDetail();
		}
		// If there are any dish types that match the filters, we highlight them
		if (allergens.length > 0 && filteredDishTypesIds.length > 0) {
			highlightFilteredDishes(
				filteredDishTypesIds,
				availableDishTypeIds,
				toggleLight,
				openDetailDishTypeId,
			);
		} else {
			// If no dish types match the filters,
			// we turn set the fridge lights to StandBy
			toggleFridgeLightingMode(LightingModeType.StandBy);
		}
	};

	return {
		handleOpen,
		closeOpenedDishTypeDetail,
		handleLightsStateWhenFilterIsActive,
		openDishTypeDetail,
	};
};

export default useMealListEvents;
