import { useQuery } from "@apollo/client";
import { batch } from "react-redux";
import { setQrCode } from "slices/accessSlice";
import { setInitialDishTypes } from "slices/dishesSlice";
import { setDoorLockState } from "slices/doorSlice";
import { setFridgeId, setSegments } from "slices/fridgeInfoSlice";
import { setLightingMode } from "slices/fridgeLightingSlice";
import { setOrder } from "slices/transactionSlice";
import { AppDispatch } from "store";
import { INITIAL_STATE_QUERY } from "utils/gqlOperations";
import { deepOmit } from "utils/utils";

import { useAppDispatch } from "hooks/utilsHooks/storeHooks";
import { Fridge, Order } from "types";
import { LocalFridgeState } from "types/mainTypes";

import { useOrder } from "../useOrder";

export const handleSetInitialData = (
	data: Fridge | LocalFridgeState,
	appDispatch: AppDispatch,
	order?: Order | null,
) => {
	const cleanedData = deepOmit(data, ["__typename"]) as LocalFridgeState;
	batch(() => {
		appDispatch(setFridgeId(cleanedData.id));
		if (cleanedData.access?.qrCode?.code) {
			appDispatch(setQrCode(cleanedData.access.qrCode.code));
		}
		appDispatch(setInitialDishTypes(cleanedData.dishes?.types));
		if (cleanedData.segments) {
			appDispatch(setSegments(cleanedData.segments));
		}
		appDispatch(setDoorLockState(cleanedData.door));
		if (cleanedData.lighting?.mode) {
			appDispatch(setLightingMode(cleanedData.lighting.mode));
		}
		if (cleanedData.transaction?.orders?.last) {
			appDispatch(setOrder(cleanedData.transaction.orders.last));
		}
		if (cleanedData.transaction?.orders?.last) {
			appDispatch(setOrder(cleanedData.transaction.orders.last));
		}
		if (order && order.state && !cleanedData.transaction) {
			appDispatch(setOrder(null));
		}
	});
};

type Props = {
	fridgeId: string;
};

export const useInitialStateQuery = ({ fridgeId }: Props) => {
	const appDispatch = useAppDispatch();
	const { order } = useOrder();

	const { loading, refetch } = useQuery(INITIAL_STATE_QUERY, {
		variables: { id: fridgeId },
		fetchPolicy: "network-only",
		refetchWritePolicy: "overwrite",
		onCompleted: ({ fridge }) => {
			handleSetInitialData(fridge, appDispatch, order);
		},
	});

	return {
		loading,
		refetch,
	};
};
