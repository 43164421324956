import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { DoorState, LockState } from "types";
import { FridgeDoorLockState } from "types/mainTypes";

type DoorSlice = {
	door: {
		state: DoorState | null;
		lock: {
			state: LockState | null;
			timeout: number | null;
		};
	};
};

const initialState: DoorSlice = {
	door: {
		state: DoorState.Closed,
		lock: {
			state: LockState.Locked,
			timeout: 20,
		},
	},
};

export const doorSlice = createSlice({
	name: "door",
	initialState,
	reducers: {
		setDoorState: (state, { payload }: PayloadAction<DoorState>) => {
			state.door.state = payload;
		},
		setLockState: (state, { payload }: PayloadAction<LockState>) => {
			state.door.lock.state = payload;
		},
		setLockTimeout: (state, { payload }: PayloadAction<number>) => {
			state.door.lock.timeout = payload;
		},
		setDoorLockState: (state, { payload }: PayloadAction<FridgeDoorLockState>) => {
			state.door = payload;
		},
	},
});

export const { setDoorState, setLockState, setLockTimeout, setDoorLockState } = doorSlice.actions;

export const selectDoorState = (state: RootState) => state.door?.door?.state;
export const selectLockState = (state: RootState) => state.door?.door?.lock.state;
export const selectLockTimeout = (state: RootState) => state.door?.door?.lock.timeout;

export default doorSlice.reducer;
