import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SnackbarHeader } from "components/primitives/snackbar/SnackbarHeader";
import { SnackbarSubHeader } from "components/primitives/snackbar/SnackbarSubtitle";
import { User } from "types/mainTypes";

import { SnackbarType, getSnackbarData } from "./snackbarTypes";

type Props = {
	user?: User;
	variant: SnackbarType;
};

const SnackbarContentComp = ({ user, variant }: Props, ref: ForwardedRef<unknown>) => {
	const { t } = useTranslation();

	const { header, subheader, image } = useMemo(
		() => getSnackbarData(t, variant, user?.name),
		[t, variant, user],
	);

	return (
		<Box display={"flex"} justifyContent={"space-between"} ref={ref}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"flex-start"}
				justifyContent={"center"}
			>
				<SnackbarHeader>{header}</SnackbarHeader>
				{subheader && <SnackbarSubHeader>{subheader}</SnackbarSubHeader>}
			</Box>
			<img src={image.src} alt={image.alt} width={132} height={132} />
		</Box>
	);
};

export const SnackbarContent = forwardRef(SnackbarContentComp);
