import { useEffect, useState } from "react";
import { useIdleTimerContext } from "react-idle-timer";
import { selectDishTypes } from "slices/dishesSlice";
import { selectOrder, setOrder } from "slices/transactionSlice";

import { useAppContext } from "contexts/AppContext";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { Dish, Maybe, Order } from "types";
import { OrderSummary } from "types/mainTypes";

import { useAnalyticsContext } from "../contexts/AnalyticsContext";
import { PaymentMethodType } from "../gql/graphql";

type AWSOrderUpdate = {
	paymentMethod: Maybe<PaymentMethodType> | undefined;
	order: Order | null;
	orderDishes: Dish[];
	orderSummary: OrderSummary | null;
	finishOrder: () => void;
};

export const useOrder = (): AWSOrderUpdate => {
	const order = useAppSelector(selectOrder);
	const dishTypes = useAppSelector(selectDishTypes);
	const dispatch = useAppDispatch();
	const { dispatch: appContextDispatch } = useAppContext();
	const [orderSummary, setOrderSummary] = useState<OrderSummary | null>(null);
	const idleTimer = useIdleTimerContext();
	const analyticsContext = useAnalyticsContext();

	useEffect(() => {
		const orderDishes = order?.inventory?.removed;

		if (order) {
			if (orderDishes) {
				const orderDishTypes = dishTypes?.filter(
					(dishType) =>
						orderDishes.find((dish) => dish?.type?.id === dishType.id) !== undefined,
				);
				setOrderSummary({
					orderDishTypes: orderDishTypes ?? [],
					orderPrice: order?.price,
				});
			}
		}
	}, [order, order?.state, order?.inventory?.removed?.length]);

	const finishOrder = () => {
		idleTimer.reset();
		appContextDispatch({ type: "CHANGE_LANGUAGE", payload: "primary" });
		analyticsContext.reset();
		dispatch(setOrder(null));
	};

	return {
		paymentMethod: order?.payment?.method?.type,
		orderDishes: order?.inventory?.removed ?? [],
		order,
		orderSummary: orderSummary ?? null,
		finishOrder,
	};
};
