/**
 * Sets new fridgeId in localStorage and reloads the page in case
 * no fridge is provided in query params or in localStorage
 */
const setupNewFridgeId = () => {
	const newFridgeId = prompt("Please provide fridgeId in query params");
	if (newFridgeId !== null) {
		localStorage.setItem("fridgeId", newFridgeId);
		window.location.reload();
	}
};

/**
 * Gets query parameter from URL or localStorage.
 * @param paramName Query parameter name.
 * @param paramDefaultValue Parameter default value.
 * @returns Current parameter value.
 */
const getQueryParamByName = (paramName: string, paramDefaultValue: string) => {
	const paramFromURL = new URLSearchParams(window.location.search).get(paramName);
	const paraFromLocalStorage = localStorage.getItem(paramName);

	// Return the param from local storage if available
	if (paraFromLocalStorage !== null && paramFromURL === null) {
		return paraFromLocalStorage;
	}

	// Update local storage with the query param and return it
	if (paramFromURL !== null) {
		localStorage.setItem(paramName, paramFromURL);
		return paramFromURL;
	}

	localStorage.setItem(paramName, paramDefaultValue);
	return paramDefaultValue;
};

/**
 * Returns fridgeId from query params or localStorage
 */
export const getFridgeId = () => {
	const fridgeId = getQueryParamByName("fridgeId", "");

	if (fridgeId !== "") {
		return fridgeId;
	}

	// Set up a new fridge ID if none is found
	setupNewFridgeId();
};

export enum TabletTypeEnum {
	Master = "master",
	Standard = "standard",
}

export const getTabletTypeFromUrl = () => {
	const tabletTypeFromQueryParam = new URLSearchParams(window.location.search).get("tabletType");

	if (tabletTypeFromQueryParam !== null && tabletTypeFromQueryParam === TabletTypeEnum.Master) {
		localStorage.setItem("tabletType", TabletTypeEnum.Master);
	} else {
		localStorage.setItem("tabletType", TabletTypeEnum.Standard);
	}
};

export const getTabletType = () => {
	const tabletTypeFromLocalStorage = localStorage.getItem("tabletType");

	if (tabletTypeFromLocalStorage !== null) {
		return tabletTypeFromLocalStorage;
	}

	getTabletTypeFromUrl();
};

const DEFAULT_LOCALE = "cs-CZ";
const LOCALE_KEY = "locale";

/**
 * Gets current instance locale. Primary source is URL.
 * @returns Current instance default locale.
 */
export const getLocale = () => {
	return getQueryParamByName(LOCALE_KEY, DEFAULT_LOCALE);
};
export const getIsMasterTablet = () => {
	const tabletType = getTabletType();
	return tabletType === TabletTypeEnum.Master;
};
