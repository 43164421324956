import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

type AccessSlice = {
	qrCode: {
		code: string | null;
	};
};

const initialState: AccessSlice = {
	qrCode: {
		code: null,
	},
};

export const accessSlice = createSlice({
	name: "access",
	initialState,
	reducers: {
		setQrCode: (state, { payload }: PayloadAction<string | null>) => {
			state.qrCode.code = payload;
		},
	},
});

export const { setQrCode } = accessSlice.actions;
export const selectQRCode = (state: RootState) => state.access.qrCode.code;
export default accessSlice.reducer;
