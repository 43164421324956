/**
 * Generates random number in the range of min and max. Uses Math.random() method.
 * @param min Minimum number (inclusive)
 * @param max Maximum number (inclusive)
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @returns Random number in the range of min and max
 */
export const getRandomNumberInRange = (min: number, max: number) => {
	const minCeiled = Math.ceil(min);
	const maxFloored = Math.floor(max);
	// The maximum is inclusive and the minimum is inclusive
	return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
};
