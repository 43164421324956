import { FC } from "react";

import { Flex } from "components/primitives/Flex";
import { H3 } from "components/primitives/Headings";
import { useDishTypes } from "hooks/useDishTypes";
import { useFridgeInformation } from "hooks/useFridgeInformation";

import { Segment } from "./Segment";
import { SegmentsWrapper } from "./styled";

export const RefillMode: FC = () => {
	const { data: stock } = useDishTypes();

	const { segments } = useFridgeInformation();

	if (!segments) {
		return null;
	}

	return (
		<Flex direction="column" grow gap="8" css={{ pb: "$15" }}>
			{segments.map(({ segment }, i) => (
				<SegmentsWrapper key={i} gap="7" align="stretch" direction="column">
					<H3 css={{ textAlign: "center" }}>Segment {i + 1}</H3>
					<Flex direction="column" gap="6">
						{stock && segment && (
							<Segment
								key={segment?.type}
								title={segment?.type || ""}
								segmentCapacity={segment.capacity || 0}
								stock={stock}
							/>
						)}
					</Flex>
				</SegmentsWrapper>
			))}
		</Flex>
	);
};
