import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "utils/theme";

import { CloseDoorModal } from "components/global/modals/CloseDoorModal";
import { ActionButton } from "components/primitives/ActionBarButton";
import { StyledBottomMessage } from "components/primitives/StyledBottomMessage";

export const OpenedDoorWarningBox = ({ resetCountdown }: { resetCountdown: () => void }) => {
	const { t } = useTranslation();

	const handleCountdownReset = () => {
		resetCountdown();
	};

	return (
		<StyledBottomMessage
			data-testid="stock-check-warning-message"
			sx={{ flexDirection: "column", alignItems: "flex-start", height: "auto" }}
		>
			<Typography variant={"promoHeader"}>{t("openedDoorWarning.title")}</Typography>
			<Typography variant={"promoText"}>{t("openedDoorWarning.body")}</Typography>
			<Box
				sx={{
					marginTop: 2,
					display: "flex",
					justifyContent: "flex-start",
					width: "100%",
				}}
			>
				<ActionButton
					variant={"contained"}
					color={"primary"}
					sx={{
						marginRight: theme.spacing(2),
						backgroundColor: theme.palette.common.black,
						color: theme.palette.common.white,
						padding: "16px 24px",
						"&:hover": {
							backgroundColor: theme.palette.common.black,
						},
					}}
					onClick={() => handleCountdownReset()}
					stretch={true}
				>
					{t("openedDoorWarning.confirmButton")}
				</ActionButton>
				<CloseDoorModal
					toggleButton={
						<ActionButton
							variant={"contained"}
							stretch={true}
							sx={{
								backgroundColor: theme.palette.darkBackground.default,
								color: theme.palette.common.black,
								padding: "16px 24px",
							}}
						>
							{t("openedDoorWarning.userLeftButton")}
						</ActionButton>
					}
				/>
			</Box>
		</StyledBottomMessage>
	);
};
