import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/react";
import { SentryLink } from "apollo-link-sentry";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { AUTH_TYPE } from "aws-appsync-auth-link/lib/auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { useEffect, useState } from "react";
import { handleSendApolloErrorToSentry } from "services/external/sentry";

import { getFridgeId } from "./browser/localStorage";

export const useFridgeApolloClient = () => {
	const [isConnected, setIsConnected] = useState(true);
	const [isSubscriptionBroken, setIsSubscriptionBroken] = useState(false);

	const url = import.meta.env.VITE_AWS_APPSYNC_URL;
	const region = import.meta.env.VITE_AWS_REGION;
	const auth: AuthOptions = {
		type: AUTH_TYPE.AWS_LAMBDA,
		token: import.meta.env.VITE_AWS_APPSYNC_API_KEY,
	};
	const connectionTimeout =
		import.meta.env.VITE_CONNECTION_TIMEOUT !== undefined
			? parseInt(import.meta.env.VITE_CONNECTION_TIMEOUT)
			: 90000;

	/* Create custom apollo link, that checks for network errors on subscription.
	If there is a network error, sets isConnected to false which triggers ping requests to Google.
	These requests are active until we have response from Google DNS,
	which means we are connected to the internet. */

	const errorLink = onError((error) => {
		handleSendApolloErrorToSentry(error);
	});

	const link = ApolloLink.from([
		errorLink,
		new RetryLink({
			attempts: (count, _operation, error) => {
				if (error) {
					if (_operation.operationName === "FridgeSubscription") {
						setIsSubscriptionBroken(true);
					}
					if (count === 3) {
						setIsConnected(false);
						return false;
					}
					return true;
				}
				return false;
			},
			delay: {
				initial: 500,
				max: 2000,
			},
		}),
		new SentryLink({
			attachBreadcrumbs: {
				includeError: true,
				includeFetchResult: true,
				includeQuery: true,
				includeVariables: true,
			},
		}),
		createAuthLink({ url, region, auth }),
		createSubscriptionHandshakeLink(
			{
				url,
				region,
				auth,
				keepAliveTimeoutMs: connectionTimeout,
			},
			new HttpLink({ uri: url }),
		),
	]);

	const client = new ApolloClient({
		link,
		cache: new InMemoryCache(),
	});

	useEffect(() => {
		if (!isConnected) {
			const ping = setInterval(async () => {
				await fetch("https://dns.google", { mode: "no-cors" })
					.then(() => {
						setIsConnected(true);
						clearInterval(ping);
						return;
					})
					.catch((error: unknown) => {
						Sentry.withScope((scope) => {
							scope.setExtra("fridgeId", getFridgeId());
							Sentry.captureException(
								new Error("Could not ping Google DNS - Tablet is offline", {cause: error}),
							);
						});
					});
			}, 5000);
		}
	}, [isConnected]);

	return {
		client,
		isConnected,
		isSubscriptionBroken,
		setIsSubscriptionBroken,
	};
};
