import { useEffect, useState } from "react";

export const useCountdown = (initialState: number, callback?: () => void) => {
	const [isRunning, setIsRunning] = useState(true);
	const [countdown, setCountdown] = useState(initialState);

	const handleResetTimeout = (isRunning?: boolean) => {
		setCountdown(initialState);
		if (isRunning !== undefined) {
			setIsRunning(isRunning);
		}
	};

	useEffect(() => {
		setCountdown(initialState);
	}, [initialState]);

	useEffect(() => {
		if (isRunning) {
			const timer = setInterval(() => {
				if (countdown > 0) {
					setCountdown((prevState) => prevState - 1);
				} else {
					callback && callback();
					setIsRunning(false);
					clearTimeout(timer);
				}
			}, 1000);
			return () => clearTimeout(timer);
		}
	}, [isRunning, countdown]);

	return { countdown, startCountdown: setIsRunning, resetCountdown: handleResetTimeout };
};
