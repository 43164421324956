import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { usePriceFormat } from "hooks/formatting/usePriceFormat";
import { Maybe, Price } from "types";
import { DishItemDisplayType } from "types/mainTypes";

type PriceWrapperProps = {
	price: Maybe<Price> | undefined;
	type: DishItemDisplayType;
};

/**
 * Component, that handles displaying price in different styles based on discount
 * @param price
 * @param type
 */
export const PriceWrapper = ({ price, type }: PriceWrapperProps) => {
	const { formatPrice, parsePrice } = usePriceFormat();

	if (!price) {
		return null;
	}

	// current price should be always available
	const currentPrice = price?.current ? parsePrice(price.current) : 0;
	// default price is populated in case of discount
	const defaultPrice = price?.default ? parsePrice(price.default) : null;
	// show discounted price only if default is set and is higher than current price
	const isDiscounted = defaultPrice !== null && currentPrice < defaultPrice;

	const isListType = type === DishItemDisplayType.LIST;

	return (
		<>
			<StyledCurrentPrice component={"span"} isListType={isListType}>
				{formatPrice(currentPrice, price.currency)}
			</StyledCurrentPrice>
			{isDiscounted && (
				<StyledDefaultPrice
					component={"span"}
					hasDiscount={isDiscounted}
					isListType={isListType}
				>
					{formatPrice(defaultPrice, price.currency)}
				</StyledDefaultPrice>
			)}
		</>
	);
};

type StyledPriceBaseProps = TypographyProps & { isListType?: boolean };
type StyledDefaultPriceProps = StyledPriceBaseProps & { hasDiscount?: boolean };

const StyledCurrentPrice = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "component" && prop !== "isListType",
})<StyledPriceBaseProps>(({ theme, isListType }) => ({
	fontSize: "1.75rem",
	lineHeight: "1.75rem",
	fontWeight: 600,
	marginRight: theme.spacing(2),
	color: isListType ? theme.palette.copper.main : theme.palette.common.white,
	opacity: isListType ? 1 : 0.75,
}));

const StyledDefaultPrice = styled(Typography, {
	shouldForwardProp: (prop) =>
		prop !== "component" && prop !== "isListType" && prop !== "hasDiscount",
})<StyledDefaultPriceProps>(({ theme, isListType, hasDiscount }) => ({
	fontSize: "1.75rem",
	lineHeight: "1.75rem",
	color: isListType ? theme.palette.silver.main : theme.palette.common.white,
	opacity: isListType ? 1 : 0.3,
	textDecoration: hasDiscount ? "line-through" : "none",
	fontWeight: hasDiscount ? 500 : 600,
}));
