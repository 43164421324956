import { MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FunctionComponent, SVGAttributes, createElement } from "react";

type LanguagePickerOptionProps = {
	onClick: () => void;
	languageIcon: FunctionComponent<SVGAttributes<SVGElement>>;
	languageName: string;
};

export function LanguagePickerOption({
	onClick,
	languageIcon,
	languageName,
}: LanguagePickerOptionProps) {
	return (
		<StyledLanguageOption onClick={onClick}>
			{createElement(languageIcon)}
			<Typography fontWeight={900} variant={"h4"} textTransform={"uppercase"}>
				{languageName}
			</Typography>
		</StyledLanguageOption>
	);
}

const StyledLanguageOption = styled(MenuItem)(() => ({
	padding: "16px",
	display: "flex",
	alignItems: "center",

	"& svg": {
		height: 48,
		width: 48,
		marginRight: 10,
	},
}));
