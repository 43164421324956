import { selectDoorState, selectLockState, selectLockTimeout } from "slices/doorSlice";

import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { DoorState, LockState } from "types";
import { LocalDoorsAndLockState } from "types/mainTypes";

export const useDoorAndLockState = (): LocalDoorsAndLockState => {
	const door = useAppSelector(selectDoorState);
	const lock = useAppSelector(selectLockState);
	const lockTimeout = useAppSelector(selectLockTimeout);

	return {
		doors: door ?? DoorState.Closed,
		lock: lock ?? LockState.Locked,
		lockTimeout: lockTimeout ?? null,
	};
};
