import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { LightingModeType } from "types";

type FridgeLightingSlice = {
	mode: LightingModeType;
};

const initialState: FridgeLightingSlice = {
	mode: LightingModeType.StandBy,
};

export const fridgeLightingSlice = createSlice({
	name: "lighting",
	initialState,
	reducers: {
		setLightingMode: (state, { payload }: PayloadAction<LightingModeType>) => {
			state.mode = payload;
		},
	},
});

export const { setLightingMode } = fridgeLightingSlice.actions;
export const selectFridgeLightingMode = (state: RootState) => state.lighting?.mode;

export default fridgeLightingSlice.reducer;
