import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { usePriceFormat } from "hooks/formatting/usePriceFormat";
import { Maybe, Price } from "types";

export const OrderPriceSummary = ({ orderPrice }: { orderPrice: Maybe<Price> | undefined }) => {
	const { t } = useTranslation();
	const { formatPrice, parsePrice } = usePriceFormat();

	const currentPrice = orderPrice?.current ? parsePrice(orderPrice.current) : 0;
	const defaultPrice = orderPrice?.default ? parsePrice(orderPrice.default) : 0;
	const discount = currentPrice - defaultPrice;

	return (
		<>
			{orderPrice?.current ? (
				<Box display={"flex"} flexDirection={"column"}>
					<Typography variant={"promoHeader"} textAlign={"right"}>
						{t("order.summary.totalPrice")}
						{formatPrice(orderPrice.current, orderPrice.currency)}
					</Typography>
					{orderPrice.default && discount > 0 && (
						<Typography variant={"promoText"} color={"white"} textAlign={"right"}>
							{t("order.summary.savings")}
							{formatPrice(discount, orderPrice.currency)}
						</Typography>
					)}
				</Box>
			) : null}
		</>
	);
};
