import { useEffect, useRef } from "react";

/**
 * Wraps the setInterval function in a hook.
 * @param callback Function to call periodically
 * @param delay Interval in milliseconds, pass `null` to clear the interval
 * @see https://www.joshwcomeau.com/snippets/react-hooks/use-interval/
 * @returns Reference to the setInterval
 */
export const useInterval = (callback: () => void, delay: number | null) => {
	const intervalRef = useRef<number | null>(null);
	const savedCallback = useRef(callback);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === "number") {
			intervalRef.current = window.setInterval(tick, delay);
			return () => {
				if (intervalRef.current !== null) {
					window.clearInterval(intervalRef.current);
				}
			};
		}
	}, [delay]);

	return intervalRef;
};
