import React from "react";

import HelpIcon from "assets/help.svg";
import { BasicModal } from "components/global/modals/BasicModal";
import { ActionButton } from "components/primitives/ActionBarButton";

import { TutorialModalContent } from "./TutorialModalContent";

export const TutorialModal = () => {
	return (
		<BasicModal
			toggleButton={
				<ActionButton>
					<HelpIcon />
				</ActionButton>
			}
			closable={true}
			modalContent={<TutorialModalContent />}
			fullHeight={true}
		/>
	);
};
