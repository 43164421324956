import { Box, Typography, styled } from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimerContext } from "react-idle-timer";
import { getSpotlightDishTypeId } from "utils/lightingUtils";
import { filterAvailableDishTypes } from "utils/mealListUtils";

import NoFiltersMatchImage from "assets/illustrations/noMatchFiltersImage.svg";
import DishTypeItem from "components/dishTypeItem";
import { Header } from "components/global/components/Header";
import { useAppContext } from "contexts";
import { useFilters } from "hooks/useFilters";
import { useFridgeLights } from "hooks/useFridgeLights";
import useMealListEvents from "hooks/useMealListEvents";
import { useOrder } from "hooks/useOrder";
import { LightingModeType, TransactionState } from "types";
import { DishItemDisplayType, LocalDishType } from "types/mainTypes";

import { TutorialModalContent } from "../drawerActionBar/components/TutorialModal/TutorialModalContent";
import { BasicModal } from "../global/modals/BasicModal";
import { DownloadAppBanner } from "../howToBanner/DownloadAppBanner";

/**
 * Represents the properties for the MealList component.
 *
 * @typedef {Object} MealListProps
 * @property {LocalDishType[]} fridgeDishes - An array of LocalDishType objects
 * representing the dishes in the fridge.
 * @property {string[]} availableDishTypeIds - An array of strings representing
 * the available dish type IDs.
 */
type MealListProps = {
	fridgeDishes: LocalDishType[];
	availableDishTypeIds: string[];
};
/**
 * Represents a component that displays a list of meal types.
 *
 * @param {MealListProps} props - The props for the MealList component.
 */
export const MealList = ({ fridgeDishes, availableDishTypeIds }: MealListProps) => {
	const { openDetailDishTypeId, dispatch: appContextDispatch } = useAppContext();
	const { allergens } = useFilters();
	const { toggleLight, dishTypeLights, toggleFridgeLightingMode, highlightSingleDishType } =
		useFridgeLights();
	const {
		handleOpen,
		closeOpenedDishTypeDetail,
		handleLightsStateWhenFilterIsActive,
		openDishTypeDetail,
	} = useMealListEvents(availableDishTypeIds, fridgeDishes);
	const { order } = useOrder();
	const { t } = useTranslation();
	const [availableDishTypes, setAvailableDishTypes] = useState<LocalDishType[]>([]);
	const [isHowToBannerVisible, setIsHowToBannerVisible] = useState(true);
	const howToBannerRef = useRef<HTMLDivElement>(null);

	const { resetAllergens } = useFilters();
	const idleTimer = useIdleTimerContext();

	const handleHowToBannerVisibility = (state?: boolean) => {
		if (state !== undefined && state !== null) {
			setIsHowToBannerVisible(state);
		} else {
			setIsHowToBannerVisible((prev) => !prev);
		}
	};

	useEffect(function setInitialOpenedDishType() {
		if (dishTypeLights) {
			const initialHighlightedDishTypeId = getSpotlightDishTypeId(dishTypeLights);
			if (initialHighlightedDishTypeId !== null && allergens.length === 0) {
				openDishTypeDetail(initialHighlightedDishTypeId);
			}
		}
	}, []);

	useEffect(
		function setupAvailableDishTypes() {
			const filteredDishes = filterAvailableDishTypes(
				fridgeDishes,
				availableDishTypeIds,
				allergens,
			);
			setAvailableDishTypes(filteredDishes);
		},
		[allergens, availableDishTypeIds],
	);

	useEffect(
		function handleFridgeLightAndDishDetailState() {
			// If user selects any filters, we highlight only filtered dish types
			if (allergens.length !== 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			} else {
				if (!idleTimer.isIdle()) {
					if (openDetailDishTypeId) {
						handleOpen(openDetailDishTypeId);
					}
					if (order?.state !== TransactionState.Finished) {
						toggleFridgeLightingMode(LightingModeType.Active);
						return;
					}
				}
			}
		},
		[allergens],
	);

	useEffect(
		function handleSingleDishTypeAction() {
			if (openDetailDishTypeId !== null && !idleTimer.isIdle()) {
				highlightSingleDishType(toggleLight, availableDishTypeIds, openDetailDishTypeId);
				openDishTypeDetail(openDetailDishTypeId);
				return;
			}
			if (!openDetailDishTypeId && allergens.length > 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			}
			if (!openDetailDishTypeId && !idleTimer.isIdle()) {
				closeOpenedDishTypeDetail();
				toggleFridgeLightingMode(LightingModeType.Active);
			}
		},
		[openDetailDishTypeId],
	);

	useEffect(
		function resetViewWhenIdle() {
			if (!order) {
				closeOpenedDishTypeDetail();
				resetAllergens();
				handleHowToBannerVisibility(true);
				appContextDispatch({ type: "CHANGE_LANGUAGE", payload: "primary" });
				const timeout = setTimeout(() => {
					howToBannerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
				}, 100);
				return () => {
					window.clearTimeout(timeout);
				};
			}
			if (idleTimer.isIdle() && !order) {
				toggleFridgeLightingMode(LightingModeType.StandBy);
			}
		},
		[idleTimer.isIdle(), order],
	);

	useEffect(() => {
		if (order && isHowToBannerVisible) {
			handleHowToBannerVisibility(false);
		}
	}, [order]);

	return (
		<>
			<Header />
			{fridgeDishes && availableDishTypes.length === 0 && allergens.length > 0 && (
				<StyledEmptyFridgePageContent>
					<StyledEmptyFridgeHeadline variant={"promoHeader"}>
						{t("dishListPage.filteredEmptyState")}
					</StyledEmptyFridgeHeadline>
					<NoFiltersMatchImage />
				</StyledEmptyFridgePageContent>
			)}
			<StyledDishTypeItemsWrapper data-testid="meal-list-dishes">
				<BasicModal
					toggleButton={
						<DownloadAppBanner
							ref={howToBannerRef}
							isVisible={isHowToBannerVisible}
							toggleBanner={handleHowToBannerVisibility}
						/>
					}
					closable={true}
					modalContent={<TutorialModalContent />}
					fullHeight={true}
				/>

				{availableDishTypes.map((dishType) => (
					<DishTypeItem
						dishType={dishType}
						type={DishItemDisplayType.LIST}
						isOpen={openDetailDishTypeId === dishType.id}
						onOpen={(ref: RefObject<HTMLDivElement>) => handleOpen(dishType.id, ref)}
						key={`mealListPage_item_${dishType.id}`}
					/>
				))}
			</StyledDishTypeItemsWrapper>
		</>
	);
};

const StyledDishTypeItemsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(2),
	overflow: "auto",
	height: "calc(100vh - 350px)",
	paddingBottom: "200px",
}));

const StyledEmptyFridgePageContent = styled(Box)(({ theme }) => ({
	width: "100%",
	height: "100%",
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	paddingTop: theme.spacing(2.5),
}));

const StyledEmptyFridgeHeadline = styled(Typography)(({ theme }) => ({
	textAlign: "center",
	color: theme.palette.copper.main,
	marginBottom: theme.spacing(3),
}));
