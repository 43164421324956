// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
	CurrencyType,
	FridgeTransactionSubscription,
	TransactionState,
} from "../../../../src/gql/graphql";
import { WSDataVariant } from "../types";
import { removedMealsLargeOrder } from "./finishedTransaction.data";

export const dishRemovedFridgeTransactionBase: FridgeTransactionSubscription = {
	orders: {
		last: {
			id: "b87d1af6-55e6-4109-88bc-038de9e36cf2",
			type: null,
			state: TransactionState.Active,
			user: {
				id: "874e7eeb-06bd-4eb5-8f3f-9e34b71ac205",
				name: "Playwright User",
				__typename: "UserSubscription",
			},
			inventory: {
				removed: [
					{
						id: "556baf7b-cdbe-4915-96ef-acce10336e0a",
						type: {
							id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
							__typename: "DishTypeSubscription",
						},
						__typename: "DishSubscription",
					},
				],
				added: null,
				updated: null,
				__typename: "TransactionInventorySubscription",
			},
			payment: null,
			price: {
				total: {
					default: "159",
					current: "159",
					currency: CurrencyType.Czk,
					__typename: "PriceSubscription",
				},
				__typename: "OrderPriceSubscription",
			},
			__typename: "OrderSubscription",
		},
		__typename: "OrdersSubscription",
	},
	__typename: "FridgeTransactionSubscription",
};

export const getDishRemovedData = (variant: WSDataVariant) => {
	const data: FridgeTransactionSubscription = { ...dishRemovedFridgeTransactionBase };
	switch (variant) {
		case WSDataVariant.LARGE_ORDER:
			data.orders.last.inventory.removed = [...removedMealsLargeOrder];
			break;
		case WSDataVariant.SMALL_ORDER:
			data.orders.last.inventory.removed = [
				{
					id: "556baf7b-cdbe-4915-96ef-acce10336e0a",
					type: {
						id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
						__typename: "DishTypeSubscription",
					},
					__typename: "DishSubscription",
				},
			];
			break;
		case WSDataVariant.NATIVE_PAYMENT:
		default:
			// no need to alter data
			break;
	}
	return data;
};
