import { Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";

export const SnackbarHeader: FC<TypographyProps> = ({ children }) => {
	return (
		<Typography
			variant={"body1"}
			fontSize={"40px"}
			lineHeight={"48px"}
			fontWeight={900}
			width={"400px"}
			marginBottom={0}
		>
			{children}
		</Typography>
	);
};
