import { Box, Button, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";

import { ModalContentWrapper } from "components/primitives/ModalContentWrapper";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { ModalContentProps } from "types/mainTypes";

import { TutorialStepper } from "./TutorialStepper";
import { TutorialSteps } from "./TutorialSteps";

/**
 * Tutorial modal content
 * @param onClose
 * @constructor
 */
export const TutorialModalContent = ({ onClose }: ModalContentProps) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const { t } = useTranslation();
	const { trackTutorialStep } = useMixpanelTrackers();

	useEffect(() => {
		trackTutorialStep(activeStep);
	}, [activeStep]);

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	return (
		<ModalContentWrapper>
			<Typography variant={"promoHeader"} alignSelf={"flex-start"} mb={3}>
				{t("tutorial.title")}
			</Typography>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={activeStep}
				style={{ overflow: "visible" }}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				containerStyle={{
					width: "calc(100vw - 80px)",
				}}
				className={"swipeableViews"}
			>
				{TutorialSteps.map((step, index) => (
					<Box key={step.title}>
						{Math.abs(activeStep - index) <= 2 ? (
							<StyledStepIllustrationWrapper component={step.svg} />
						) : null}
					</Box>
				))}
			</SwipeableViews>
			<TutorialStepper activeStep={activeStep} onStepChange={handleStepChange} />
			<Typography variant={"cta"} textAlign={"center"} width={"90%"} mb={1}>
				{t(`${TutorialSteps[activeStep].title}`)}
			</Typography>

			<StyledCloseButton
				color={"black"}
				variant={"contained"}
				onClick={() => onClose && onClose()}
			>
				{t("tutorial.confirmButton")}
			</StyledCloseButton>
			<Typography variant={"badge"} textAlign={"center"} width={"400px"}>
				version: {import.meta.env.APP_VERSION} (
				{import.meta.env.VITE_BUILD_NUMBER ?? "12345"})
			</Typography>
		</ModalContentWrapper>
	);
};

const StyledStepIllustrationWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: 640,
	overflow: "hidden",
	padding: `8px ${theme.spacing(3)}`,
	margin: "auto",
}));

const StyledCloseButton = styled(Button)(({ theme }) => ({
	color: theme.palette.common.white,
	padding: "16px 24px",
	marginTop: "auto",
	marginBottom: "16px",
}));
