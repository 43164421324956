import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeaderText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "hasBorder",
})<TypographyProps & { hasBorder?: boolean }>(({ theme, hasBorder }) => ({
	fontWeight: 900,
	width: "100%",
	textTransform: "uppercase",
	color: theme.palette.common.white,
	span: {
		color: theme.palette.copper.dark,
	},
	...(hasBorder && {
		paddingBottom: "8px",
		width: "fit-content",
		borderBottom: "6px solid white",
		marginBottom: "16px",
	}),
}));
