import { useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { SEND_FEEDBACK_MUTATION } from "utils/gqlOperations";

import ThumbsDownIcon from "assets/icons/thumbsDownIcon.svg";
import ThumbsUpIcon from "assets/icons/thumbsUpIcon.svg";
import { ReportIssueModal } from "components/global/modals/ReportIssueModal";
import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { StyledBottomMessage } from "components/primitives/StyledBottomMessage";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { useFridgeInformation } from "hooks/useFridgeInformation";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { OrderFeedbackRatingType } from "types";

type RatingBoxProps = {
	resetOrder: () => void;
	transactionId: string;
	onNegativeFeedbackClick?: () => void;
};

export const RatingBox = ({
	resetOrder,
	transactionId,
	onNegativeFeedbackClick,
}: RatingBoxProps) => {
	const { t } = useTranslation();
	const { id } = useFridgeInformation();
	const { openSnackbarWithContent } = useSnackbarContext();
	const { trackFeedbackPurchaseCompleted, trackOpenReportAnIssueModal } = useMixpanelTrackers();

	const [sendPositiveFeedbackMutation, { loading }] = useMutation(SEND_FEEDBACK_MUTATION);

	// We do not really care when the feedback is sent or finished sending,
	// we just go on so next customer can buy food
	// eslint-disable-next-line @typescript-eslint/require-await
	const sendPositiveFeedback = async () => {
		openSnackbarWithContent(<SnackbarContent variant={"feedbackPositive"} />);
		trackFeedbackPurchaseCompleted(OrderFeedbackRatingType.POSITIVE);
		if (id && transactionId) {
			sendPositiveFeedbackMutation({
				variables: {
					input: {
						orderId: transactionId,
						type: OrderFeedbackRatingType.POSITIVE,
					},
				},
			});
		}
		resetOrder();
	};

	const handleNegativeFeedbackClick = () => {
		trackFeedbackPurchaseCompleted(OrderFeedbackRatingType.NEGATIVE);
		trackOpenReportAnIssueModal();
		if (onNegativeFeedbackClick) {
			onNegativeFeedbackClick();
		}
	};

	return (
		<StyledBottomMessage
			display={"flex"}
			gap={"16px"}
			flexWrap={"wrap"}
			justifyContent={"center"}
		>
			<Typography variant={"promoHeader"} maxWidth={"55%"}>
				{t("order.step5.feedback.header")}
			</Typography>
			<RatingButton
				onClick={() => sendPositiveFeedback()}
				variant={"contained"}
				color={"black"}
				disabled={loading}
				data-testid="thumbsUp-btn"
			>
				<Typography variant={"button"}></Typography>
				<ThumbsUpIcon />
			</RatingButton>
			<ReportIssueModal
				toggleButton={
					<RatingButton
						variant={"contained"}
						color={"black"}
						disabled={loading}
						data-testid="thumbsDown-btn"
					>
						<ThumbsDownIcon />
						<Typography variant={"button"}></Typography>
					</RatingButton>
				}
				transactionId={transactionId}
				resetOrder={resetOrder}
				onClick={() => handleNegativeFeedbackClick()}
			/>
		</StyledBottomMessage>
	);
};

const RatingButton = styled(Button)(({ theme }) => ({
	color: theme.palette.common.white,
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	gap: "8px",
}));
