import { useAppContext } from "contexts";
import { Filters } from "types/mainTypes";

export const useFilters = () => {
	const {
		filters: { allergens },
		dispatch,
	} = useAppContext();

	const setFilters = (payload: Partial<Filters>) => {
		dispatch({
			type: "SET_FILTERS",
			payload,
		});
	};

	const resetAllergens = () => {
		if (allergens.length > 0) {
			setFilters({ allergens: [] });
		}
	};

	return {
		allergens,
		setFilters,
		resetAllergens,
	};
};
