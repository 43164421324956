export enum TabletDishDetailSectionEnum {
	About = "About",
	Ingredients = "Ingredients",
	Instructions = "Instructions",
}

export enum TabletTutorialStepEnum {
	ScanTheQRCode = "Scan the QR Code",
	OpenTheFridge = "Open the Fridge",
	TakeYourMeal = "Take your Meal",
	PurchaseIsFinalised = "Purchase is Finalised",
}

export enum MixpanelActionEventsEnum {
	Tablet_Open_Place_Your_Card = "$tablet_open_place_your_card",
	Tablet_Open_The_Fridge = "$tablet_open_the_fridge",
	Tablet_Open_Take_Your_Dishes = "$tablet_open_take_your_dishes",
	Tablet_Open_Scanning_The_Fridge = "$tablet_open_scanning_the_fridge",
	Tablet_Open_Purchase_Completed = "$tablet_open_purchase_completed",
	Tablet_Open_Nothing_Taken = "$tablet_open_nothing_taken",
	Tablet_Open_Tutorial = "$tablet_open_tutorial",
	Tablet_Session_Started = "Tablet Session Started",
	Tablet_Order_Started = "$tablet_order_started",
	Tablet_Order_Finished = "$tablet_order_finished",
	Tablet_Order_Finished_Nothing_taken = "$tablet_order_finished_nothing_taken",
	Tablet_Open_Detail_Of_Dish = "$tablet_open_detail_of_dish",
	Tablet_Open_Report_Issue_Modal = "$tablet_open_report_an_issue",
	Tablet_Close_Detail_Of_Dish = "$tablet_close_detail_of_dish",
	Tablet_Drawer_Collapsed = "$tablet_drawer_collapsed",
	Tablet_Drawer_Expanded = "$tablet_drawer_expanded",
	Tablet_Filter_Applied = "$tablet_filter_applied",
	Tablet_Filer_Reset = "$tablet_filter_reset",
	Tablet_Back_To_Home_Button = "$tablet_back_to_home_button_pressed",
	Tablet_Language_Changed = "$tablet_language_changed",
	Tablet_Feedback_Nothing_Taken = "$tablet_feedback_nothing_taken",
	Tablet_Feedback_Purchase_Completed = "$tablet_feedback_purchase_completed",
	Tablet_Feedback_Report_An_Issue = "$tablet_feedback_report_an_issue",
	Tablet_Feedback_Dish_Taken = "$tablet_dish_taken",
	Tablet_Feedback_Dish_Put_Back = "$tablet_dish_put_back",
	Tablet_Door_Opened = "$tablet_door_opened",
	Tablet_Door_Closed = "$tablet_door_closed",
	Tablet_Door_Not_Opened_In_Time = "$tablet_door_was_not_opened_in_time",
	Tablet_Scanning_Ended = "$tablet_scanning_ended",
	Tablet_Scanning_Started = "$tablet_scanning_started",
	Tablet_Close_Banner_Download_app = "$tablet_close_banner_download_the_app",
	Tablet_Click_Banner_Download_app = "$tablet_click_banner_download_the_app",
}

export enum MixpanelTabletFieldsEnum {
	Tablet_Feedback_Purchase_Completed_Value = "$feedback_purchase_completed_value",
	Tablet_Feedback_Nothing_Taken_value = "$feedback_nothing_taken_value",
	Tablet_Feedback_Report_Issue_Value = "$feedback_report_an_issue_value",
}

export enum MixpanelTabletTypeEnum {
	Master = "Master",
	Standard = "Standard",
}
