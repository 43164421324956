import { Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useRef } from "react";

import DishTypeItem from "components/dishTypeItem";
import { OrderPriceSummary } from "components/global/components/OrderPriceSummary";
import { BlurredBackground } from "components/primitives/BlurredBackground";
import { DrawerToggleIndicator } from "components/primitives/DrawerToggleIndicator";
import { SwipeableArea } from "components/primitives/SwipeableArea";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { Maybe, Order } from "types";
import { DishItemDisplayType, OrderSummary } from "types/mainTypes";

import { PaymentMethodType } from "../../gql/graphql";
import { ORDER_STEP_5_SMALL_ORDER_LIMIT } from "../../utils/designTokens";
import { EmptyOrderState } from "./EmptyOrderState";
import { BaseOrderStepProps } from "./OrderStep2";
import { StyledDishTypeList } from "./OrderStep3";
import { Order5DrawerHeader } from "./components/OrderStep5Header";
import { RatingBox } from "./components/RatingBox";

type OrderStep5Props = {
	paymentMethod: Maybe<PaymentMethodType> | undefined;
	orderSummary: OrderSummary | null;
	resetOrder: () => void;
	transactionId: string;
	order: Order | null;
	doorHasBeenOpened: boolean;
};

export const OrderStep5 = ({
	open,
	orderSummary,
	resetOrder,
	transactionId,
	order,
	toggleDrawer,
	paymentMethod,
	doorHasBeenOpened,
}: BaseOrderStepProps & OrderStep5Props) => {
	const {
		trackStockCheckEnded,
		trackFinishedOrderScreen,
		trackFinishedOrder,
		trackFinishedEmptyOrder,
		trackDoorNotOpened,
	} = useMixpanelTrackers();
	const isEmptyOrder =
		(orderSummary?.orderDishTypes && orderSummary.orderDishTypes.length === 0) ?? false;
	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	const clearTimeoutHandler = () => {
		clearTimeout(timerRef.current);
		return () => clearTimeout(timerRef.current);
	};

	useEffect(() => {
		timerRef.current = setTimeout(() => {
			resetOrder();
		}, 10000);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, []);

	// UseEffect hook to track the finished order screen and the finished order event
	useEffect(() => {
		trackStockCheckEnded();
		trackFinishedOrderScreen(isEmptyOrder);
		if (!isEmptyOrder) {
			trackFinishedOrder(transactionId, order, orderSummary);
		} else {
			if (!doorHasBeenOpened) {
				trackDoorNotOpened();
			}
			trackFinishedEmptyOrder(transactionId);
		}
	}, []);

	return (
		<>
			<SwipeableArea orderstep={5} open={open}>
				{!open && <BlurredBackground orderStep={5} open={open} />}
				<DrawerToggleIndicator open={open} toggleDrawer={toggleDrawer} />
				<Order5DrawerHeader isEmptyOrder={isEmptyOrder} paymentMethod={paymentMethod} />
			</SwipeableArea>
			<DrawerWrapper
				sx={{
					...(open && {
						marginTop: paymentMethod === "NATIVE_PAY_TOP_UP" ? "240px" : "220px",
					}),
				}}
			>
				{isEmptyOrder ? (
					<EmptyOrderState transactionId={transactionId} />
				) : (
					<OrderItemBlockWrapper>
						{orderSummary?.orderDishTypes && (
							<>
								<OrderDishesWrapper
									maxHeight={
										orderSummary?.orderDishTypes.length >
										ORDER_STEP_5_SMALL_ORDER_LIMIT
											? "600px"
											: "320px"
									}
								>
									<StyledDishTypeList
										sx={{ height: "750px", paddingBottom: "30px" }}
										isLargeOrder={orderSummary?.orderDishTypes.length > 1}
										data-testid="overview-dish-list"
									>
										{orderSummary?.orderDishTypes?.map((dishType, index) => (
											<Fragment key={`orderStep5DishType_${dishType.id}`}>
												<DishTypeItem
													dishType={dishType}
													type={DishItemDisplayType.ORDER}
													key={`orderStep5_item_${dishType.id}`}
													pieces={
														order?.inventory?.removed?.filter(
															(dish) => dish.type?.id === dishType.id,
														).length
													}
												/>
												{orderSummary?.orderDishTypes.length > 1 &&
													index !==
														orderSummary?.orderDishTypes.length - 1 && (
														<OrderDishDivider />
													)}
											</Fragment>
										))}
									</StyledDishTypeList>
								</OrderDishesWrapper>
								<Box>
									<OrderPriceSummary
										orderPrice={orderSummary?.orderPrice?.total}
									/>
								</Box>
							</>
						)}
						{order && !isEmptyOrder && (
							<RatingBox
								resetOrder={resetOrder}
								transactionId={order.id}
								onNegativeFeedbackClick={() => {
									clearTimeoutHandler();
								}}
							/>
						)}
					</OrderItemBlockWrapper>
				)}
			</DrawerWrapper>
		</>
	);
};

const OrderDishesWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	gap: theme.spacing(1),
	overflow: "auto",
}));

const OrderItemBlockWrapper = styled(OrderDishesWrapper)(() => ({}));

const OrderDishDivider = styled(Divider)(() => ({
	borderColor: "rgba(255, 255, 255, 0.5)",
	margin: "8px 0",
}));

const DrawerWrapper = styled(Box)(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
}));
