import {
	Dispatch,
	PropsWithChildren,
	Reducer,
	createContext,
	useContext,
	useEffect,
	useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { IdleTimerProvider, PresenceType } from "react-idle-timer";
import { getLocale } from "utils/browser/localStorage";

import { useAnalyticsContext } from "contexts/AnalyticsContext";
import { AppContextAction, AppState } from "types/mainTypes";

import { useAppSelector } from "../hooks/utilsHooks/storeHooks";
import { selectOrder } from "../slices/transactionSlice";

type AppContext = AppState & {
	dispatch: Dispatch<AppContextAction>;
};

const initialState: AppState = {
	primaryLanguage: "CZ",
	secondaryLanguage: "EN",
	selectedLanguage: "primary",
	locale: "cs-CZ",
	disableTimeouts: false,
	filters: {
		allergens: [],
	},
	appState: "active",
	openDetailDishTypeId: null,
};

export const AppContext = createContext<AppContext>({
	...initialState,
	dispatch: (value: AppContextAction) => {
		return value;
	},
});

const reducer = (state: AppState, action: AppContextAction): AppState => {
	switch (action.type) {
		case "SET_LOCALE": {
			return {
				...state,
				locale: action.payload,
			};
		}
		case "CHANGE_LANGUAGE": {
			return {
				...state,
				selectedLanguage: action.payload,
			};
		}
		case "DISABLE_TIMEOUTS": {
			return {
				...state,
				disableTimeouts: !state.disableTimeouts,
			};
		}
		case "SET_FILTERS": {
			return {
				...state,
				filters: {
					...state.filters,
					...action.payload,
				},
			};
		}
		case "SET_APP_STATE": {
			return {
				...state,
				appState: action.payload,
			};
		}
		case "SET_OPENED_DETAIL_DISH_TYPE_ID": {
			return {
				...state,
				openDetailDishTypeId: action.payload,
			};
		}
		default:
			return state;
	}
};

export const AppContextProvider = ({ children }: PropsWithChildren) => {
	const locale = getLocale();

	const [state, dispatch] = useReducer<Reducer<AppState, AppContextAction>>(reducer, {
		...initialState,
		// set initial language based on locale
		selectedLanguage: ["cs", "cs-CZ"].includes(locale) ? "primary" : "secondary",
	});
	const { i18n } = useTranslation();
	const order = useAppSelector(selectOrder);

	const analyticsContext = useAnalyticsContext();

	useEffect(() => {
		dispatch({ type: "SET_LOCALE", payload: locale });
	}, [locale]);

	useEffect(() => {
		const newLanguage = state.selectedLanguage === "primary" ? "cs" : "en";
		// change language only if it's different from the current one
		if (newLanguage !== i18n.language) {
			i18n.changeLanguage(newLanguage);
		}
	}, [state.selectedLanguage]);

	const handleResetAnalyticsContextOnIdle = (presence: PresenceType["type"]) => {
		if (presence === "idle") {
			if (!order) {
				analyticsContext.reset();
			} else {
				return;
			}
		}
	};

	const onPresenceChange = (presence: PresenceType["type"]) => {
		handleResetAnalyticsContextOnIdle(presence);
		dispatch({ type: "SET_APP_STATE", payload: presence });
	};

	return (
		<AppContext.Provider
			value={{
				...state,
				dispatch,
			}}
		>
			<IdleTimerProvider
				timeout={import.meta.env.VITE_INACTIVE_TIMEOUT}
				onPresenceChange={(presence) => onPresenceChange(presence.type)}
			>
				{children}
			</IdleTimerProvider>
		</AppContext.Provider>
	);
};

export const useAppContext = () => useContext(AppContext);
