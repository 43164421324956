import { groupBy } from "lodash";

import { LocalDishType, LocalFridgeSegment } from "types/mainTypes";

type IParsedPosition = {
	row: number;
	column: number;
	dishCount?: number;
};

/**
 * Groups segments sent from API by rows, so we can get shape of each row
 * example value {[row]: [false, false, false]}
 * @param segments
 * @param mealPositions
 */
export const groupSegmentsByRows = (
	segments: IParsedPosition[],
	mealPositions: IParsedPosition[] | undefined,
) => {
	return segments.reduce((acc: { [key: string]: Array<boolean | number> }, segment) => {
		const { row } = segment;
		if (!acc[row]) {
			acc[row] = [];
		}
		const isMealPosition = mealPositions?.find(
			(position: { row: number; column: number }) =>
				position.row === row && position.column === segment.column,
		);

		acc[row].push(
			isMealPosition !== undefined && isMealPosition.dishCount
				? isMealPosition.dishCount
				: false,
		);
		return acc;
	}, {});
};

const convertPositionToRowAndColumn = (position: string, dishCount?: number) => {
	const segmentSections = position.split("_");
	const segmentRow = parseInt(segmentSections[1]);
	const segmentColumn = parseInt(segmentSections[2]);

	return {
		row: segmentRow,
		column: segmentColumn,
		dishCount,
	};
};

/**
 * Parses information about fridge segments from API to a format that is easier to work with
 * example conversion:
 *      API response:
 *      {
 *          segments: [
 *              {position: "BOX_1_1", capacity: 1},
 *              {position: "BOX_1_2", capacity: 1},
 *              {position: "BOX_2_1", capacity: 1}
 *          ]
 *      }
 *      returns: [[false, false], [false]]
 * @param fridgeSegments
 * @param dishPositionsAndCount
 */
export const parseFridgeSegments = (
	fridgeSegments: LocalFridgeSegment[] | null,
	dishType: LocalDishType,
) => {
	if (!fridgeSegments) {
		return [];
	}
	const parsedSegments = fridgeSegments.map((fridgeSegment) => {
		if (!fridgeSegment?.segment?.type) {
			return {
				row: 0,
				column: 0,
			};
		}
		return convertPositionToRowAndColumn(fridgeSegment.segment.type);
	});

	const groupedDishes = groupBy(dishType.dishes, (dish) => dish.position);

	const parsedMealPositions = Object.keys(groupedDishes).map((dishPosition) => {
		return convertPositionToRowAndColumn(dishPosition, groupedDishes[dishPosition].length);
	});

	return Object.values(groupSegmentsByRows(parsedSegments, parsedMealPositions));
};
