import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { forwardRef } from "react";

import { Debug } from "components/global/DebugModal";
import LanguagePicker from "components/languagePicker";
import { useDoorAndLockState } from "hooks/useDoorAndLockState";
import { useOrder } from "hooks/useOrder";
import { DoorState, LockState } from "types";

import {
	AutolockTimer,
	BackToHomeButton,
	DrawerOrderSummaryRow,
	TutorialModal,
	UserSettingsModal,
} from "./components";

type DrawerActionBarProps = {
	currentOrderStep: number;
};

/**
 * App action bar, that handles displays basic information about user and order process
 */
export const DrawerActionBar = forwardRef(({ currentOrderStep }: DrawerActionBarProps, ref) => {
	const { lockTimeout, doors, lock } = useDoorAndLockState();
	const { order, finishOrder } = useOrder();
	const isDebug = import.meta.env.MODE !== "production";
	const handleFinishOrder = () => {
		finishOrder();
	};

	const isUserLoggedIn = order?.user && currentOrderStep !== 1 && currentOrderStep !== 5;
	const doorsClosedAndUnlocked =
		lockTimeout && doors === DoorState.Closed && lock === LockState.Unlocked;

	return (
		<ActionBar
			ref={ref}
			className={"actionBar"}
			sx={{
				width: currentOrderStep === 1 ? "330px" : "100vw",
			}}
			data-testid="action-bar"
		>
			<LanguagePicker />
			<TutorialModal />
			{isUserLoggedIn && <UserSettingsModal />}
			{isDebug && <Debug />}
			{doorsClosedAndUnlocked && <AutolockTimer lockTimeout={lockTimeout} />}
			<DrawerOrderSummaryRow orderStep={currentOrderStep} />
			{currentOrderStep === 5 && <BackToHomeButton finishOrder={() => handleFinishOrder()} />}
		</ActionBar>
	);
});

const ActionBar = styled(Box)(({ theme }) => ({
	position: "fixed",
	bottom: 0,
	left: 0,
	right: 0,
	padding: "40px ",
	display: "flex",
	zIndex: 1201,
	gap: theme.spacing(2),
	alignItems: "center",
}));

DrawerActionBar.displayName = "DrawerActionBar";
