import { RefillMode } from "components/RefillPage/RefillMode";
import { RefillLayout } from "layouts/RefillLayout";

export const RefillModePage = () => {
	return (
		<RefillLayout>
			<RefillMode />
		</RefillLayout>
	);
};
