import { selectFridgeId, selectFridgeSegments } from "slices/fridgeInfoSlice";

import { useAppSelector } from "./utilsHooks/storeHooks";

export const useFridgeInformation = () => {
	const segments = useAppSelector(selectFridgeSegments);
	const id = useAppSelector(selectFridgeId);

	return {
		segments,
		id,
	};
};
