import { styled } from "@mui/material/styles";
import React, { FC, PropsWithChildren } from "react";

import { DrawerContextProvider } from "contexts/DrawerContext";
import { SnackbarContextProvider } from "contexts/SnackbarContext";

export const DefaultMain = styled("main")(({ theme }) => ({
	padding: "24px 40px",
	display: "flex",
	flexDirection: "column",
	flexGrow: 1,
	backgroundColor: theme.palette.common.black,
	height: "100vh",
	overflow: "hidden",
}));

export const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<DefaultMain>
			<SnackbarContextProvider>
				<DrawerContextProvider>
					{children}
					{/* <ActionBar />*/}
				</DrawerContextProvider>
			</SnackbarContextProvider>
		</DefaultMain>
	);
};
