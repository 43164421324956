import CloseIcon from "@mui/icons-material/Close";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ElementType } from "react";

export function FilterChipLabel({
	label,
	isFiltered,
	icon,
}: {
	label: string;
	isFiltered?: boolean;
	icon?: ElementType;
}) {
	return (
		<StyledFilterChipLabel isFiltered={isFiltered}>
			{label}
			{isFiltered ? <StyledIcon component={CloseIcon} /> : <StyledIcon component={icon} />}
		</StyledFilterChipLabel>
	);
}

const StyledIcon = styled(Box)(({ theme }) => ({
	marginLeft: theme.spacing(1),
	backgroundColor: theme.palette.common.white,
	borderRadius: "50%",
	color: theme.palette.common.black,
}));

const StyledFilterChipLabel = styled(Box, {
	name: "StyledFilterChipLabel",
	shouldForwardProp: (propName: PropertyKey) => propName !== "isFiltered",
})<BoxProps & { isFiltered?: boolean }>(({ theme, isFiltered }) => ({
	display: "flex",
	alignItems: "center",
	color: isFiltered ? theme.palette.common.black : theme.palette.common.white,
	borderRadius: "80px",
	marginLeft: 0,
	padding: 0,
	...(isFiltered && {
		"& svg": {
			padding: 0,
		},
	}),
	...((!isFiltered || false) && {
		"& svg": {
			padding: theme.spacing(0.5),
		},
		"& svg path": {
			fill: theme.palette.copper.main,
		},
	}),
}));
