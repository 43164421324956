import { AllergenType } from "types";
import { LocalDishType } from "types/mainTypes";

/**
 * Returns the number of meals that satisfy the filter criteria
 * @param stock
 * @param allergens
 */
export const getFilteredMealsCount = (stock: LocalDishType[], allergens: AllergenType[]) => {
	return stock
		.filter(
			(dishType) =>
				dishType.dishes &&
				dishType.dishes.length > 0 &&
				dishType.allergens?.some((allergen) => !allergens.includes(allergen)),
		)
		.filter((dish) => {
			if (dish.allergens) {
				return allergens.every((allergen) => !dish.allergens?.includes(allergen));
			}
			return false;
		}).length;
};
