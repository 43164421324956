import TutorialStep1 from "assets/illustrations/tutorial/tutorial_step1.svg";
import TutorialStep2 from "assets/illustrations/tutorial/tutorial_step2.svg";
import TutorialStep3 from "assets/illustrations/tutorial/tutorial_step3.svg";
import TutorialStep4 from "assets/illustrations/tutorial/tutorial_step4.svg";

export const TutorialSteps = [
	{
		title: "tutorial.step1.title",
		svg: TutorialStep1,
	},
	{
		title: "tutorial.step2.title",
		svg: TutorialStep2,
	},
	{
		title: "tutorial.step3.title",
		svg: TutorialStep3,
	},
	{
		title: "tutorial.step4.title",
		svg: TutorialStep4,
	},
] as const;
