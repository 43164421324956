import { Payment, PaymentSubscription, PaymentSubscriptionUpdate } from "./gql/graphql";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;

export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
};

export enum AllergenType {
	Celery = "CELERY",
	Crustaceans = "CRUSTACEANS",
	Eggs = "EGGS",
	Fish = "FISH",
	Gluten = "GLUTEN",
	Lupin = "LUPIN",
	Milk = "MILK",
	Molluscs = "MOLLUSCS",
	Mustard = "MUSTARD",
	Nuts = "NUTS",
	Peanuts = "PEANUTS",
	SesameSeeds = "SESAME_SEEDS",
	Soybeans = "SOYBEANS",
	SulphurDioxide = "SULPHUR_DIOXIDE",
	Spicy = "SPICY",
}

export enum CurrencyType {
	Czk = "CZK",
	Eur = "EUR",
}

export type Dish = {
	expiration: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	position: Maybe<DishSegmentType>;
	type: Maybe<DishType>;
};

export type DishSegment = {
	capacity: Maybe<Scalars["Int"]["output"]>;
	type: Maybe<DishSegmentType>;
};

export type DishSegmentSubscription = {
	capacity: Maybe<Scalars["Int"]["output"]>;
	type: Maybe<DishSegmentType>;
};

export type DishSegmentSubscriptionUpdate = {
	capacity: InputMaybe<Scalars["Int"]["input"]>;
	type: InputMaybe<DishSegmentType>;
};

export enum DishSegmentType {
	Box_1_1 = "BOX_1_1",
	Box_1_2 = "BOX_1_2",
	Box_1_3 = "BOX_1_3",
	Box_2_1 = "BOX_2_1",
	Box_2_2 = "BOX_2_2",
	Box_2_3 = "BOX_2_3",
	Box_3_1 = "BOX_3_1",
	Box_3_2 = "BOX_3_2",
	Box_3_3 = "BOX_3_3",
	Box_4_1 = "BOX_4_1",
	Box_4_2 = "BOX_4_2",
	Box_4_3 = "BOX_4_3",
	Box_5_1 = "BOX_5_1",
	Box_5_2 = "BOX_5_2",
	Box_5_3 = "BOX_5_3",
	Box_6_1 = "BOX_6_1",
	Box_6_2 = "BOX_6_2",
	Box_6_3 = "BOX_6_3",
}

export type DishSegmentUpdate = {
	capacity: InputMaybe<Scalars["Int"]["input"]>;
	type: InputMaybe<DishSegmentType>;
};

export type DishSubscription = {
	expiration: Maybe<Scalars["String"]["output"]>;
	id: Maybe<Scalars["ID"]["output"]>;
	position: Maybe<DishSegmentType>;
	type: Maybe<DishTypeSubscription>;
};

export type DishSubscriptionUpdate = {
	expiration: InputMaybe<Scalars["String"]["input"]>;
	id: InputMaybe<Scalars["ID"]["input"]>;
	position: InputMaybe<DishSegmentType>;
	type: InputMaybe<DishTypeSubscriptionUpdate>;
};

export type DishType = {
	allergens: Maybe<Array<AllergenType>>;
	description: Maybe<DishTypeDescription>;
	dishes: Maybe<Array<Dish>>;
	id: Scalars["ID"]["output"];
	image: Maybe<Image>;
	lighting: Maybe<DishTypeLighting>;
	price: Maybe<Price>;
	weight: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescription = {
	primary: Maybe<DishTypeDescriptionDetail>;
	secondary: Maybe<DishTypeDescriptionDetail>;
};

export type DishTypeDescriptionDetail = {
	composition: Maybe<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	name: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescriptionDetailSubscription = {
	composition: Maybe<Scalars["String"]["output"]>;
	description: Maybe<Scalars["String"]["output"]>;
	name: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeDescriptionDetailSubscriptionUpdate = {
	composition: InputMaybe<Scalars["String"]["input"]>;
	description: InputMaybe<Scalars["String"]["input"]>;
	name: InputMaybe<Scalars["String"]["input"]>;
};

export type DishTypeDescriptionSubscription = {
	primary: Maybe<DishTypeDescriptionDetailSubscription>;
	secondary: Maybe<DishTypeDescriptionDetailSubscription>;
};

export type DishTypeDescriptionSubscriptionUpdate = {
	primary: InputMaybe<DishTypeDescriptionDetailSubscriptionUpdate>;
	secondary: InputMaybe<DishTypeDescriptionDetailSubscriptionUpdate>;
};

export type DishTypeLighting = {
	mode: Maybe<LightingSegmentModeType>;
};

export type DishTypeLightingSubscription = {
	mode: Maybe<LightingSegmentModeType>;
};

export type DishTypeLightingSubscriptionUpdate = {
	mode: InputMaybe<LightingSegmentModeType>;
};

export type DishTypeLightingUpdate = {
	mode: InputMaybe<LightingSegmentModeType>;
};

export type DishTypeSubscription = {
	allergens: Maybe<Array<AllergenType>>;
	description: Maybe<DishTypeDescriptionSubscription>;
	dishes: Maybe<Array<DishSubscription>>;
	id: Scalars["ID"]["output"];
	image: Maybe<ImageSubscription>;
	lighting: Maybe<DishTypeLightingSubscription>;
	price: Maybe<PriceSubscription>;
	weight: Maybe<Scalars["String"]["output"]>;
};

export type DishTypeSubscriptionUpdate = {
	allergens: InputMaybe<Array<AllergenType>>;
	description: InputMaybe<DishTypeDescriptionSubscriptionUpdate>;
	dishes: InputMaybe<Array<DishSubscriptionUpdate>>;
	id: Scalars["ID"]["input"];
	image: InputMaybe<ImageSubscriptionUpdate>;
	lighting: InputMaybe<DishTypeLightingSubscriptionUpdate>;
	price: InputMaybe<PriceSubscriptionUpdate>;
	weight: InputMaybe<Scalars["String"]["input"]>;
};

export type DishTypeUpdate = {
	id: Scalars["ID"]["input"];
	lighting: InputMaybe<DishTypeLightingUpdate>;
};

export enum DoorState {
	Closed = "CLOSED",
	Opened = "OPENED",
}

export type Fridge = {
	access: Maybe<FridgeAccess>;
	dishes: Maybe<FridgeDishes>;
	door: Maybe<FridgeDoor>;
	id: Scalars["ID"]["output"];
	lighting: Maybe<Lighting>;
	segments: Maybe<Array<FridgeSegment>>;
	transaction: Maybe<FridgeTransaction>;
};

export type FridgeAccess = {
	qrCode: Maybe<QrCode>;
};

export type FridgeAccessSubscription = {
	qrCode: Maybe<QrCodeSubscription>;
};

export type FridgeAccessSubscriptionUpdate = {
	qrCode: InputMaybe<QrCodeSubscriptionUpdate>;
};

export type FridgeDishes = {
	items: Maybe<Array<Dish>>;
	types: Maybe<Array<DishType>>;
};

export type FridgeDishesSubscription = {
	types: Maybe<Array<DishTypeSubscription>>;
};

export type FridgeDishesSubscriptionUpdate = {
	types: InputMaybe<Array<DishTypeSubscriptionUpdate>>;
};

export type FridgeDishesUpdate = {
	types: InputMaybe<Array<DishTypeUpdate>>;
};

export type FridgeDoor = {
	lock: Maybe<FridgeDoorLock>;
	state: Maybe<DoorState>;
};

export type FridgeDoorLock = {
	state: Maybe<LockState>;
	timeout: Maybe<Scalars["Int"]["output"]>;
};

export type FridgeDoorLockSubscription = {
	state: Maybe<LockState>;
	timeout: Maybe<Scalars["Int"]["output"]>;
};

export type FridgeDoorLockSubscriptionUpdate = {
	state: InputMaybe<LockState>;
	timeout: InputMaybe<Scalars["Int"]["input"]>;
};

export type FridgeDoorSubscription = {
	lock: Maybe<FridgeDoorLockSubscription>;
	state: Maybe<DoorState>;
};

export type FridgeDoorSubscriptionUpdate = {
	lock: InputMaybe<FridgeDoorLockSubscriptionUpdate>;
	state: InputMaybe<DoorState>;
};

export type FridgeSegment = {
	light: Maybe<SegmentLight>;
	segment: Maybe<DishSegment>;
};

export type FridgeSegmentSubscription = {
	light: Maybe<SegmentLightSubscription>;
	segment: Maybe<DishSegmentSubscription>;
};

export type FridgeSegmentSubscriptionUpdate = {
	light: InputMaybe<SegmentLightSubscriptionUpdate>;
	segment: InputMaybe<DishSegmentSubscriptionUpdate>;
};

export type FridgeSegmentUpdate = {
	light: InputMaybe<SegmentLightUpdate>;
	segment: InputMaybe<DishSegmentUpdate>;
};

export type FridgeSubscription = {
	access: Maybe<FridgeAccessSubscription>;
	dishes: Maybe<FridgeDishesSubscription>;
	door: Maybe<FridgeDoorSubscription>;
	id: Scalars["ID"]["output"];
	lighting: Maybe<LightingSubscription>;
	segments: Maybe<Array<FridgeSegmentSubscription>>;
	transaction: Maybe<FridgeTransactionSubscription>;
};

export type FridgeSubscriptionUpdate = {
	access: InputMaybe<FridgeAccessSubscriptionUpdate>;
	dishes: InputMaybe<FridgeDishesSubscriptionUpdate>;
	door: InputMaybe<FridgeDoorSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	lighting: InputMaybe<LightingSubscriptionUpdate>;
	segments: InputMaybe<Array<FridgeSegmentSubscriptionUpdate>>;
	transaction: InputMaybe<FridgeTransactionSubscriptionUpdate>;
};

export type FridgeTransaction = {
	order: Maybe<Order>;
	orders: Maybe<{ last: Order }>;
	refill: Maybe<Refill>;
};

export type FridgeTransactionSubscription = {
	order: Maybe<OrderSubscription>;
	orders: Maybe<{ last: OrderSubscription }>;
	refill: Maybe<RefillSubscription>;
};

export type FridgeTransactionSubscriptionUpdate = {
	order: InputMaybe<OrderSubscriptionUpdate>;
	refill: InputMaybe<RefillSubscriptionUpdate>;
};

export type FridgeUpdate = {
	dishes: InputMaybe<FridgeDishesUpdate>;
	id: Scalars["ID"]["input"];
	lighting: InputMaybe<LightingUpdate>;
	segments: InputMaybe<Array<FridgeSegmentUpdate>>;
};

export type Image = {
	url: Maybe<Scalars["String"]["output"]>;
};

export type ImageSubscription = {
	url: Maybe<Scalars["String"]["output"]>;
};

export type ImageSubscriptionUpdate = {
	url: InputMaybe<Scalars["String"]["input"]>;
};

export type LedLight = {
	color: Maybe<LightColor>;
	position: Maybe<Scalars["Int"]["output"]>;
};

export type LedLightSubscription = {
	color: Maybe<LightColorSubscription>;
	position: Maybe<Scalars["Int"]["output"]>;
};

export type LedLightSubscriptionUpdate = {
	color: InputMaybe<LightColorSubscriptionUpdate>;
	position: InputMaybe<Scalars["Int"]["input"]>;
};

export type LedLightUpdate = {
	color: InputMaybe<LightColorUpdate>;
	position: InputMaybe<Scalars["Int"]["input"]>;
};

export type LightColor = {
	b: Maybe<Scalars["Int"]["output"]>;
	g: Maybe<Scalars["Int"]["output"]>;
	r: Maybe<Scalars["Int"]["output"]>;
	w: Maybe<Scalars["Int"]["output"]>;
};

export type LightColorSubscription = {
	b: Maybe<Scalars["Int"]["output"]>;
	g: Maybe<Scalars["Int"]["output"]>;
	r: Maybe<Scalars["Int"]["output"]>;
	w: Maybe<Scalars["Int"]["output"]>;
};

export type LightColorSubscriptionUpdate = {
	b: InputMaybe<Scalars["Int"]["input"]>;
	g: InputMaybe<Scalars["Int"]["input"]>;
	r: InputMaybe<Scalars["Int"]["input"]>;
	w: InputMaybe<Scalars["Int"]["input"]>;
};

export type LightColorUpdate = {
	b: InputMaybe<Scalars["Int"]["input"]>;
	g: InputMaybe<Scalars["Int"]["input"]>;
	r: InputMaybe<Scalars["Int"]["input"]>;
	w: InputMaybe<Scalars["Int"]["input"]>;
};

export type Lighting = {
	mode: Maybe<LightingModeType>;
};

export enum LightingModeType {
	Active = "ACTIVE",
	StandBy = "STAND_BY",
}

export enum LightingSegmentModeType {
	Default = "DEFAULT",
	Spotlight = "SPOTLIGHT",
}

export type LightingSubscription = {
	mode: Maybe<LightingModeType>;
};

export type LightingSubscriptionUpdate = {
	mode: InputMaybe<LightingModeType>;
};

export type LightingUpdate = {
	mode: InputMaybe<LightingModeType>;
};

export enum LockState {
	Locked = "LOCKED",
	Unlocked = "UNLOCKED",
}

export type Mutation = {
	updateFridge: Fridge;
	updateFridgeSubscription: FridgeSubscription;
};

export type Order = Transaction & {
	access: Maybe<TransactionAccess>;
	end: Maybe<Scalars["String"]["output"]>;
	fridge: Maybe<Fridge>;
	id: Scalars["ID"]["output"];
	inventory: Maybe<TransactionInventory>;
	payment: Maybe<Payment>;
	price: Maybe<OrderPrice>;
	start: Maybe<Scalars["String"]["output"]>;
	state: Maybe<TransactionState>;
	type: Maybe<TransactionType>;
	user: Maybe<User>;
};

export type OrderEmptyFeedback = {
	comment: Maybe<Scalars["String"]["output"]>;
	reason: Maybe<OrderEmptyFeedbackReasonType>;
};

export enum OrderEmptyFeedbackReasonType {
	Other = "OTHER",
}

export type OrderEmptyFeedbackUpdate = {
	comment: InputMaybe<Scalars["String"]["input"]>;
	reason: InputMaybe<OrderEmptyFeedbackReasonType>;
};

export type OrderPrice = {
	total: Maybe<Price>;
};

export type OrderPriceSubscription = {
	total: Maybe<PriceSubscription>;
};

export type OrderPriceSubscriptionUpdate = {
	total: InputMaybe<PriceSubscriptionUpdate>;
};

export type OrderSubscription = {
	access: Maybe<TransactionAccessSubscription>;
	end: Maybe<Scalars["String"]["output"]>;
	fridge: Maybe<FridgeSubscription>;
	id: Scalars["ID"]["output"];
	inventory: Maybe<TransactionInventorySubscription>;
	payment: Maybe<PaymentSubscription>;
	price: Maybe<OrderPriceSubscription>;
	start: Maybe<Scalars["String"]["output"]>;
	state: Maybe<TransactionState>;
	type: Maybe<TransactionType>;
	user: Maybe<UserSubscription>;
};

export type OrderSubscriptionUpdate = {
	access: InputMaybe<TransactionAccessSubscriptionUpdate>;
	end: InputMaybe<Scalars["String"]["input"]>;
	fridge: InputMaybe<FridgeSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	inventory: InputMaybe<TransactionInventorySubscriptionUpdate>;
	payment: InputMaybe<PaymentSubscriptionUpdate>;
	price: InputMaybe<OrderPriceSubscriptionUpdate>;
	start: InputMaybe<Scalars["String"]["input"]>;
	state: InputMaybe<TransactionState>;
	type: InputMaybe<TransactionType>;
	user: InputMaybe<UserSubscriptionUpdate>;
};

export type Price = {
	currency: Maybe<CurrencyType>;
	current: Maybe<Scalars["String"]["output"]>;
	default: Maybe<Scalars["String"]["output"]>;
};

export type PriceSubscription = {
	currency: Maybe<CurrencyType>;
	current: Maybe<Scalars["String"]["output"]>;
	default: Maybe<Scalars["String"]["output"]>;
};

export type PriceSubscriptionUpdate = {
	currency: InputMaybe<CurrencyType>;
	current: InputMaybe<Scalars["String"]["input"]>;
	default: InputMaybe<Scalars["String"]["input"]>;
};

export type PriceUpdate = {
	currency: InputMaybe<CurrencyType>;
	current: InputMaybe<Scalars["String"]["input"]>;
	default: InputMaybe<Scalars["String"]["input"]>;
};

export type QrCode = {
	code: Maybe<Scalars["String"]["output"]>;
};

export type QrCodeSubscription = {
	code: Maybe<Scalars["String"]["output"]>;
};

export type QrCodeSubscriptionUpdate = {
	code: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
	fridge: Fridge;
};

export type Refill = Transaction & {
	access: Maybe<TransactionAccess>;
	end: Maybe<Scalars["String"]["output"]>;
	fridge: Maybe<Fridge>;
	id: Scalars["ID"]["output"];
	inventory: Maybe<TransactionInventory>;
	start: Maybe<Scalars["String"]["output"]>;
	state: Maybe<TransactionState>;
	type: Maybe<TransactionType>;
	user: Maybe<User>;
};

export type RefillSubscription = {
	access: Maybe<TransactionAccessSubscription>;
	end: Maybe<Scalars["String"]["output"]>;
	fridge: Maybe<FridgeSubscription>;
	id: Scalars["ID"]["output"];
	inventory: Maybe<TransactionInventorySubscription>;
	start: Maybe<Scalars["String"]["output"]>;
	state: Maybe<TransactionState>;
	type: Maybe<TransactionType>;
	user: Maybe<UserSubscription>;
};

export type RefillSubscriptionUpdate = {
	access: InputMaybe<TransactionAccessSubscriptionUpdate>;
	end: InputMaybe<Scalars["String"]["input"]>;
	fridge: InputMaybe<FridgeSubscriptionUpdate>;
	id: Scalars["ID"]["input"];
	inventory: InputMaybe<TransactionInventorySubscriptionUpdate>;
	start: InputMaybe<Scalars["String"]["input"]>;
	state: InputMaybe<TransactionState>;
	type: InputMaybe<TransactionType>;
	user: InputMaybe<UserSubscriptionUpdate>;
};

export type SegmentLedLights = {
	all: Maybe<LightColor>;
	leds: Maybe<Array<LedLight>>;
};

export type SegmentLedLightsSubscription = {
	all: Maybe<LightColorSubscription>;
	leds: Maybe<Array<LedLightSubscription>>;
};

export type SegmentLedLightsSubscriptionUpdate = {
	all: InputMaybe<LightColorSubscriptionUpdate>;
	leds: InputMaybe<Array<LedLightSubscriptionUpdate>>;
};

export type SegmentLedLightsUpdate = {
	all: InputMaybe<LightColorUpdate>;
	leds: InputMaybe<Array<LedLightUpdate>>;
};

export type SegmentLight = {
	ambient: Maybe<SegmentLedLights>;
	front: Maybe<SegmentLedLights>;
};

export type SegmentLightSubscription = {
	ambient: Maybe<SegmentLedLightsSubscription>;
	front: Maybe<SegmentLedLightsSubscription>;
};

export type SegmentLightSubscriptionUpdate = {
	ambient: InputMaybe<SegmentLedLightsSubscriptionUpdate>;
	front: InputMaybe<SegmentLedLightsSubscriptionUpdate>;
};

export type SegmentLightUpdate = {
	ambient: InputMaybe<SegmentLedLightsUpdate>;
	front: InputMaybe<SegmentLedLightsUpdate>;
};

export type Subscription = {
	fridge: Maybe<FridgeSubscription>;
};

export type Transaction = {
	access: Maybe<TransactionAccess>;
	end: Maybe<Scalars["String"]["output"]>;
	fridge: Maybe<Fridge>;
	id: Scalars["ID"]["output"];
	inventory: Maybe<TransactionInventory>;
	start: Maybe<Scalars["String"]["output"]>;
	state: Maybe<TransactionState>;
	type: Maybe<TransactionType>;
	user: Maybe<User>;
};

export type TransactionAccess = {
	type: Maybe<TransactionAccessType>;
};

export type TransactionAccessSubscription = {
	type: Maybe<TransactionAccessType>;
};

export type TransactionAccessSubscriptionUpdate = {
	type: InputMaybe<TransactionAccessType>;
};

export enum TransactionAccessType {
	QrCode = "QR_CODE",
}

export type TransactionInventory = {
	added: Maybe<Array<Dish>>;
	removed: Maybe<Array<Dish>>;
	updated: Maybe<Array<Dish>>;
};

export type TransactionInventorySubscription = {
	added: Maybe<Array<DishSubscription>>;
	removed: Maybe<Array<DishSubscription>>;
	updated: Maybe<Array<DishSubscription>>;
};

export type TransactionInventorySubscriptionUpdate = {
	added: InputMaybe<Array<DishSubscriptionUpdate>>;
	removed: InputMaybe<Array<DishSubscriptionUpdate>>;
	updated: InputMaybe<Array<DishSubscriptionUpdate>>;
};

export enum TransactionState {
	Active = "ACTIVE",
	Finished = "FINISHED",
	StockCheck = "STOCK_CHECK",
}

export enum TransactionType {
	Order = "ORDER",
	Refill = "REFILL",
}

export type User = {
	id: Scalars["ID"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
};

export type UserSubscription = {
	id: Scalars["ID"]["output"];
	name: Maybe<Scalars["String"]["output"]>;
};

export type UserSubscriptionUpdate = {
	id: Scalars["ID"]["input"];
	name: InputMaybe<Scalars["String"]["input"]>;
};

export type UserUpdate = {
	id: Scalars["ID"]["input"];
	name: InputMaybe<Scalars["String"]["input"]>;
};

export type NutritionValues = {
	energy: number;
	fat: {
		total: number;
		saturated: number;
	};
	saccharides: {
		total: number;
		sugar: number;
	};
	protein: number;
	salt: number;
};

export enum OrderFeedbackRatingType {
	NEGATIVE = "NEGATIVE",
	POSITIVE = "POSITIVE",
}

export enum OrderFeedbackReasonType {
	JUST_LOOKING = "JUST_LOOKING",
	NOTHING_INTERESTING = "NOTHING_INTERESTING",
	NOT_CHARGED = "NOT_CHARGED",
	OTHER = "OTHER",
	OVERCHARGED = "OVERCHARGED",
	SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}
