import CloseIcon from "@mui/icons-material/Close";
import { Box, BoxProps, IconButton, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement, cloneElement, useEffect, useState } from "react";

type BasicModalProps = {
	toggleButton?: ReactElement;
	modalContent: ReactElement;
	closable?: boolean;
	fullHeight?: boolean;
	open?: boolean;
	onClick?: () => void;
	onClose?: () => void;
	shouldClose?: boolean;
};

export const BasicModal = ({
	toggleButton,
	modalContent,
	closable,
	fullHeight,
	open,
	onClick,
	shouldClose,
	onClose,
}: BasicModalProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isOpen = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (onClick) {
			onClick();
		}
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		if (onClose) {
			onClose();
		}
		setAnchorEl(null);
	};

	useEffect(() => {
		if (shouldClose) {
			setAnchorEl(null);
		}
	}, [shouldClose]);

	return (
		<>
			{toggleButton &&
				cloneElement(toggleButton, {
					onClick: (e: React.MouseEvent<HTMLElement>) => handleClick(e),
				})}
			<Modal
				open={open !== undefined ? open : isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				data-testid="modal"
			>
				<StyledBasicModal fullHeight={fullHeight}>
					<>
						{closable && (
							<StyledIconButton onClick={() => handleClose()} size={"large"}>
								<CloseIcon fontSize={"large"} />
							</StyledIconButton>
						)}
						{cloneElement(modalContent, {
							onClose: () => handleClose(),
						})}
					</>
				</StyledBasicModal>
			</Modal>
		</>
	);
};

const StyledBasicModal = styled(Box, {
	shouldForwardProp: (prop) => prop !== "fullHeight",
})<BoxProps & { fullHeight?: boolean }>(({ theme, fullHeight }) => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	maxHeight: "100vh",
	transform: "translate(-50%, -50%)",
	backgroundColor: theme.palette.common.white,
	borderRadius: theme.shape.borderRadius,
	margin: "auto",
	height: fullHeight ? "calc(100vh - 80px)" : "auto",
	width: fullHeight ? "calc(100vw - 80px)" : "auto",
	padding: fullHeight ? 0 : theme.spacing(4),
	outline: "none",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: "40px",
	top: "20px",
	color: theme.palette.common.black,
}));
