import React from "react";

import { CloseButton, Puller } from "./SwipeableArea";

type DrawerToggleIndicatorProps = {
	open: boolean;
	toggleDrawer: (newState: boolean) => void;
};

export const DrawerToggleIndicator = ({ open, toggleDrawer }: DrawerToggleIndicatorProps) => {
	const handleToggleDrawer = (newState: boolean) => {
		toggleDrawer(newState);
	};

	return open ? <CloseButton onClick={() => handleToggleDrawer(false)} /> : <Puller />;
};
