import { Typography } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import { useTranslation } from "react-i18next";
import { selectShortFridgeId } from "slices/fridgeInfoSlice";

import EmptyFridgeIllustration from "assets//illustrations/emptyFridgeImage.svg";
import { ErrorBox } from "components/primitives/ErrorBox";
import { ModalContentWrapper } from "components/primitives/ModalContentWrapper";
import { ModalTitle } from "components/primitives/ModalTitle";
import { useAppSelector } from "hooks/utilsHooks/storeHooks";

import { BasicModal } from "./BasicModal";

const EmptyFridgeModalContent = () => {
	const fridgeId = useAppSelector(selectShortFridgeId);
	const { t } = useTranslation();
	return (
		<ModalContentWrapper>
			<ModalTitle variant={"promoHeader"}>{t("modals.emptyFridgeModal.title")}</ModalTitle>
			<Typography variant={"promoText"}>
				{t("modals.emptyFridgeModal.description")}
			</Typography>
			<EmptyFridgeIllustration />
			<ErrorBox mt={"auto"}>
				<Typography
					display={"flex"}
					variant={"ctaSubtextBold"}
					color={"white"}
					textAlign={"center"}
				>
					{parse(t("modals.emptyFridgeModal.banner"))}
				</Typography>
			</ErrorBox>
			<Typography variant={"filter"}>
				{t("modals.emptyFridgeModal.fridgeId")}: {fridgeId}
			</Typography>
		</ModalContentWrapper>
	);
};

export const EmptyFridgeModal = () => {
	return (
		<BasicModal
			modalContent={<EmptyFridgeModalContent />}
			open={true}
			closable={false}
			fullHeight={true}
		/>
	);
};
