import { TFunction } from "i18next";

import helloAnimation from "assets/animations/animation_hello.gif";
import feedbackNegativeAnimation from "assets/animations/feedback_negative.gif";
import feedbackPositiveAnimation from "assets/animations/feedback_positive.gif";

const SnackbarType = {
	WELCOME: "welcome",
	FEEDBACK_POSITIVE: "feedbackPositive",
	FEEDBACK_NEGATIVE: "feedbackNegative",
	EMPTY_ORDER: "emptyOrder",
	ORDER_NOT_STARTED: "orderNotStarted",
	ORDER_FINISHED: "orderFinished",
} as const;

export type SnackbarType = (typeof SnackbarType)[keyof typeof SnackbarType];

type ReturnType = {
	header: string;
	subheader?: string;
	image: {
		src: string;
		alt: string;
	};
};

export const getSnackbarData = (
	t: TFunction,
	type: SnackbarType,
	username?: string,
): ReturnType => {
	const usernameString = username ?? "";
	switch (type) {
		case SnackbarType.WELCOME:
			return {
				header: t("order.step2.snackbar.header", {
					userName: usernameString,
					// values are unescaped to support names variations, eg. with quotes
					// value is not rendered dangerously
					interpolation: { escapeValue: false },
				}),
				image: { src: helloAnimation, alt: "Hello animation" },
			};
		case SnackbarType.FEEDBACK_NEGATIVE:
			return {
				header: t("order.step5.snackbar.feedbackNegative.header"),
				subheader: t("order.step5.snackbar.feedbackNegative.subheader"),
				image: { src: feedbackNegativeAnimation, alt: "Feedback negative animation" },
			};
		case SnackbarType.FEEDBACK_POSITIVE:
			return {
				header: t("order.step5.snackbar.feedbackPositive.header"),
				subheader: t("order.step5.snackbar.feedbackPositive.subheader"),
				image: { src: feedbackPositiveAnimation, alt: "Feedback positive animation" },
			};
		case SnackbarType.EMPTY_ORDER:
			return {
				header: `${t("order.step5.snackbar.emptyOrder.header")}${usernameString}`,
				subheader: t("order.step5.snackbar.emptyOrder.subheader"),
				image: { src: helloAnimation, alt: "Hello animation" },
			};
		case SnackbarType.ORDER_NOT_STARTED:
			return {
				header: `${t("order.step5.snackbar.orderNotStarted.header")}${usernameString}`,
				subheader: t("order.step5.snackbar.orderNotStarted.subheader"),
				image: { src: helloAnimation, alt: "Hello animation" },
			};
		case SnackbarType.ORDER_FINISHED:
		default:
			return {
				header: `${t("order.step5.snackbar.header")}${usernameString}`,
				subheader: t("order.step5.snackbar.subheader"),
				image: { src: helloAnimation, alt: "Hello animation" },
			};
	}
};
