import { styled } from "stitches.config";

export const Flex = styled("div", {
	display: "flex",
	flexWrap: "wrap",
	position: "relative",

	variants: {
		direction: {
			column: {
				flexDirection: "column",
				flexWrap: "nowrap",
			},
			row: {
				flexDirection: "row",
				flexWrap: "wrap",
			},
			"row-reverse": {
				flexDirection: "row-reverse",
			},
			"column-reverse": {
				flexDirection: "column-reverse",
				flexWrap: "nowrap",
			},
		},
		justify: {
			start: {
				justifyContent: "flex-start",
			},
			end: {
				justifyContent: "flex-end",
			},
			center: {
				justifyContent: "center",
			},
			between: {
				justifyContent: "space-between",
			},
			around: {
				justifyContent: "space-around",
			},
			evenly: {
				justifyContent: "space-evenly",
			},
		},
		align: {
			start: {
				alignItems: "flex-start",
			},
			end: {
				alignItems: "flex-end",
			},
			center: {
				alignItems: "center",
			},
			between: {
				alignItems: "space-between",
			},
			around: {
				alignItems: "space-around",
			},
			evenly: {
				alignItems: "space-evenly",
			},
			stretch: {
				alignItems: "stretch",
			},
		},
		gap: {
			0: {
				gap: 0,
			},
			1: {
				gap: "$1",
			},
			2: {
				gap: "$2",
			},
			3: {
				gap: "$3",
			},
			4: {
				gap: "$4",
			},
			5: {
				gap: "$5",
			},
			6: {
				gap: "$6",
			},
			7: {
				gap: "$7",
			},
			8: {
				gap: "$8",
			},
			9: {
				gap: "$9",
			},
			10: {
				gap: "$10",
			},
			11: {
				gap: "$11",
			},
			12: {
				gap: "$12",
			},
			13: {
				gap: "$13",
			},
			14: {
				gap: "$14",
			},
			15: {
				gap: "$15",
			},
			16: {
				gap: "$16",
			},
		},
		rowGap: {
			0: {
				rowGap: 0,
			},
			1: {
				rowGap: "$1",
			},
			2: {
				rowGap: "$2",
			},
			3: {
				rowGap: "$3",
			},
			4: {
				rowGap: "$4",
			},
			5: {
				rowGap: "$5",
			},
			6: {
				rowGap: "$6",
			},
			7: {
				rowGap: "$7",
			},
			8: {
				rowGap: "$8",
			},
			9: {
				rowGap: "$9",
			},
			10: {
				rowGap: "$10",
			},
			11: {
				rowGap: "$11",
			},
			12: {
				rowGap: "$12",
			},
			13: {
				rowGap: "$13",
			},
			14: {
				rowGap: "$14",
			},
			15: {
				rowGap: "$15",
			},
			16: {
				rowGap: "$16",
			},
		},
		columnGap: {
			0: {
				columnGap: 0,
			},
			1: {
				columnGap: "$1",
			},
			2: {
				columnGap: "$2",
			},
			3: {
				columnGap: "$3",
			},
			4: {
				columnGap: "$4",
			},
			5: {
				columnGap: "$5",
			},
			6: {
				columnGap: "$6",
			},
			7: {
				columnGap: "$7",
			},
			8: {
				columnGap: "$8",
			},
			9: {
				columnGap: "$9",
			},
			10: {
				columnGap: "$10",
			},
			11: {
				columnGap: "$11",
			},
			12: {
				columnGap: "$12",
			},
			13: {
				columnGap: "$13",
			},
			14: {
				columnGap: "$14",
			},
			15: {
				columnGap: "$15",
			},
			16: {
				columnGap: "$16",
			},
		},
		grow: {
			true: {
				flexGrow: 1,
			},
		},
		fullHeight: {
			true: {
				height: "100%",
			},
		},
		noShrink: {
			true: {
				flexShrink: 0,
			},
		},
		noWrap: {
			true: {
				flexWrap: "nowrap",
			},
		},
		fullWidth: {
			true: {
				width: "100%",
			},
		},
	},
});
