import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Chip, ChipProps, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllergensMapping } from "utils/AllergensMapping";
import { getFilteredMealsCount } from "utils/getFilteredMealsCount";

import { useDishTypes } from "hooks/useDishTypes";
import { useFilters } from "hooks/useFilters";
import { AllergenType } from "types";
import { ModalContentProps } from "types/mainTypes";

export const FiltersModal = ({ onClose }: ModalContentProps) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { allergens, setFilters } = useFilters();
	const { data: stock } = useDishTypes();
	const [filteredStockSize, setFilteredStockSize] = useState(stock?.length);

	useEffect(
		function updateNumberOfFilteredDishTypesEffect() {
			if (stock) {
				const numberOfFilteredMeals = getFilteredMealsCount(stock, allergens);
				setFilteredStockSize(numberOfFilteredMeals);
			}
		},
		[allergens, stock?.length],
	);

	const handleFilterClick = (allergen: AllergenType) => {
		if (allergens.includes(allergen)) {
			setFilters({ allergens: allergens.filter((a) => a !== allergen) });
		} else {
			setFilters({ allergens: [...allergens, allergen] });
		}
	};

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<StyledFilterModal>
			<StyledFilterModalSection>
				<StyledFilterModalSectionTitle variant={"h5"}>
					{parse(t("filters.avoidTitle"))}
				</StyledFilterModalSectionTitle>
				{AllergensMapping.map((allergen) => (
					<StyledFilterChip
						icon={
							allergens.includes(allergen.name) ? (
								<CloseIcon color={"inherit"} fontSize={"medium"} />
							) : (
								allergen.icon
							)
						}
						size={"medium"}
						key={`filters_${allergen.name}`}
						label={t(`allergens.${allergen.name}`)}
						onClick={() => handleFilterClick(allergen.name)}
						isFiltered={allergens.includes(allergen.name)}
					/>
				))}
			</StyledFilterModalSection>

			<Box display={"flex"} gap={2} onClick={() => handleClose()} alignItems={"center"}>
				<StyledButton
					sx={{
						backgroundColor: theme.palette.common.black,
						color: theme.palette.common.white,
					}}
				>
					<Typography variant={"button"}>{t("filters.filterButton")}</Typography>
				</StyledButton>
				<StyledButton
					sx={{
						backgroundColor: theme.palette.darkBackground.default,
						color: theme.palette.common.black,
					}}
					onClick={() => setFilters({ allergens: [] })}
				>
					<Typography variant={"button"}>{t("filters.restButton")}</Typography>
				</StyledButton>

				<Typography
					variant={"body1"}
					sx={{
						fontSize: "1.25rem",
						marginLeft: "auto",
					}}
				>
					{t("filters.dishTypesFiltered", {
						count: filteredStockSize,
					})}
				</Typography>
			</Box>
		</StyledFilterModal>
	);
};

const StyledButton = styled(Button, {
	name: "FilterModalActionButton",
})(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: "16px 24px",
}));

const StyledFilterModal = styled(Box, {
	name: "StyledFilterModal",
})(() => ({
	padding: "40px",
	height: "100%",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
}));

const StyledFilterModalSection = styled(Box, {
	name: "StyledFilterModalSection",
})(({ theme }) => ({
	display: "flex",
	gap: theme.spacing(1),
	flexWrap: "wrap",
}));

const StyledFilterModalSectionTitle = styled(Typography, {
	name: "StyledFilterModalSectionTitle",
})(({ theme }) => ({
	width: "100%",
	marginBottom: theme.spacing(2),
}));

const StyledFilterChip = styled(Chip, {
	shouldForwardProp: (propName: PropertyKey) => propName !== "isFiltered",
})<ChipProps & { isFiltered: boolean }>(({ isFiltered }) => ({
	height: "3rem",
	borderRadius: "80px",
	span: {
		padding: 0,
		fontWeight: 600,
		fontSize: "1.5rem",
		lineHeight: "1.75rem",
	},
	padding: "8px 20px 8px 20px",

	"&.MuiChip-root": {
		flexFlow: "row-reverse",
		...(isFiltered && {
			backgroundColor: "black",
			color: "white",
			svg: {
				"& path": {
					fill: "white",
					color: "white",
				},
			},
		}),
	},
}));
