import { Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getFridgeId } from "utils/browser/localStorage";
import { getShortenedFridgeId } from "utils/utils";

import UserLeftIllustration from "assets/illustrations/userLeftIllustration.svg";
import { ErrorBox } from "components/primitives/ErrorBox";
import { ModalContentWrapper } from "components/primitives/ModalContentWrapper";
import { ModalTitle } from "components/primitives/ModalTitle";

import { BasicModal } from "./BasicModal";

type CloseDoorModalProps = {
	toggleButton: ReactElement;
};

export const CloseDoorModal = ({ toggleButton }: CloseDoorModalProps) => {
	const fridgeId = getFridgeId();
	const shortenedFridgeId = getShortenedFridgeId(fridgeId);

	return (
		<>
			<BasicModal
				toggleButton={toggleButton}
				fullHeight={true}
				closable={true}
				modalContent={<CloseDoorModalContent fridgeId={shortenedFridgeId} />}
			/>
		</>
	);
};

const CloseDoorModalContent = ({ fridgeId }: { fridgeId: string }) => {
	const { t } = useTranslation();
	return (
		<ModalContentWrapper>
			<ModalTitle variant={"promoHeader"}>{t("modals.openedDoorModal.title")}</ModalTitle>

			<UserLeftIllustration />
			<Typography variant={"cta"} textAlign={"center"} mb={1}>
				{t("modals.openedDoorModal.header")}
			</Typography>
			<Typography variant={"ctaSubtext"} textAlign={"center"} width={"90%"} mb={1}>
				{t("modals.openedDoorModal.body")}
			</Typography>
			<ErrorBox mt={"auto"}>
				<Typography
					display={"flex"}
					variant={"ctaSubtextBold"}
					color={"white"}
					textAlign={"center"}
				>
					{parse(t("modals.error.buttonText"))}
				</Typography>
			</ErrorBox>
			<Typography mt={1} fontWeight={600}>
				{t("modals.error.fridgeId", { fridgeId: fridgeId })}
			</Typography>
		</ModalContentWrapper>
	);
};
