import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Order, Refill } from "types";

type TransactionSlice = {
	order: Order | null;
	refill: Refill | null;
};

const initialState: TransactionSlice = {
	order: null,
	refill: null,
};

export const transactionSlice = createSlice({
	name: "transaction",
	initialState,
	reducers: {
		setOrder: (state, { payload }: PayloadAction<Order | null>) => {
			state.order = payload;
		},
		setRefill: (state, { payload }: PayloadAction<Refill | null>) => {
			state.refill = payload;
		},
	},
});

export const { setOrder } = transactionSlice.actions;
export const selectOrder = (state: RootState) => state.transaction.order;
export default transactionSlice.reducer;
