import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { parseInstructions } from "utils/utils";

import { useAppContext } from "contexts/AppContext";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { LocalDishType } from "types/mainTypes";

import { DishTypeTabPanel } from "./DishTypeTabPanel";
import { CompositionTab, DescriptionTab, InstructionsTab } from "./tabs";

export const DishTypeDetailTabs = ({ dishType }: { dishType: LocalDishType }) => {
	const [value, setValue] = React.useState(0);
	const { selectedLanguage } = useAppContext();
	const { t } = useTranslation();
	const { trackDishTypeDetailTabSwitch } = useMixpanelTrackers();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		trackDishTypeDetailTabSwitch(newValue, dishType);
		setValue(newValue);
	};

	const parsedInstructions = parseInstructions(
		dishType.description![selectedLanguage]!.description,
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<StyledTab label={t("dishTypeDetail.tabs.description")} />
					<StyledTab label={t("dishTypeDetail.tabs.composition")} />
					<StyledTab label={t("dishTypeDetail.tabs.instructions")} />
				</StyledTabs>
			</Box>
			<DishTypeTabPanel value={value} index={0}>
				<DescriptionTab dishType={dishType} />
			</DishTypeTabPanel>
			<DishTypeTabPanel value={value} index={1}>
				<CompositionTab dishType={dishType} />
			</DishTypeTabPanel>
			<DishTypeTabPanel value={value} index={2}>
				<InstructionsTab
					instructions={
						Array.isArray(parsedInstructions)
							? parsedInstructions[1]
							: parsedInstructions
					}
				/>
			</DishTypeTabPanel>
		</Box>
	);
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
	"& .MuiTabs-indicator": {
		backgroundColor: theme.palette.copper.main,
	},
}));

const StyledTab = styled(Tab)(({ theme }) => ({
	"&.MuiTab-root": {
		color: theme.palette.common.white,
		fontWeight: 600,
		fontSize: "1.5rem",
		lineHeight: "1.75rem",
		padding: "8px 8px 8px 0",
		marginRight: "8px",
		textTransform: "capitalize",
	},
}));
