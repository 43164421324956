import { Box, BoxProps, CircularProgress, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ORDER_STEP_3_SMALL_ORDER_LIMIT } from "utils/designTokens";

import OrderStep2Arrow from "assets/OrderStep2Arrow.svg";
import ChooseFood from "assets/illustrations/chooseFoodImage.svg";
import ProcessingOrderImage from "assets/illustrations/processingOrderImage.svg";
import { HeaderText } from "components//primitives/HeaderText";
import DishTypeItem from "components/dishTypeItem";
import { BlurredBackground } from "components/primitives/BlurredBackground";
import { DrawerSubHeaderText } from "components/primitives/DrawerSubHeaderText";
import { DrawerToggleIndicator } from "components/primitives/DrawerToggleIndicator";
import { StyledBottomMessage } from "components/primitives/StyledBottomMessage";
import { SwipeableArea } from "components/primitives/SwipeableArea";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { useOpenDoorAlarm } from "hooks/useOpenDoorAlarm";
import { Dish, Order, TransactionState } from "types";
import { DishItemDisplayType, LocalDishType } from "types/mainTypes";

import { BaseOrderStepProps } from "./OrderStep2";
import { OpenedDoorWarningBox } from "./components/OpenedDoorWarningBox";

const Body = styled(Box)(() => ({
	img: {
		marginTop: 16,
	},
}));

export const OrderStep3 = ({
	open,
	order,
	orderDishes,
	orderDishTypes,
	stock,
	toggleDrawer,
}: BaseOrderStepProps & {
	orderDishes: Dish[];
	orderDishTypes?: LocalDishType[];
	stock: LocalDishType[];
	order: Order | null;
}) => {
	const { t } = useTranslation();
	const isStockCheck = order?.state === TransactionState.StockCheck;
	const { trackStockCheckStarted, trackOrderStep3Screen } = useMixpanelTrackers();
	const { resetCountdown, displayOpenedDoorWarning } = useOpenDoorAlarm(order?.type);

	useEffect(() => {
		trackOrderStep3Screen();
	}, []);

	useEffect(() => {
		if (order?.state === TransactionState.StockCheck) {
			trackStockCheckStarted();
			resetCountdown();
		}
	}, [order?.state]);

	useEffect(() => {
		if (displayOpenedDoorWarning) {
			toggleDrawer(true);
		}
	}, [displayOpenedDoorWarning]);

	return (
		<>
			<SwipeableArea orderstep={3} open={open}>
				{!open && <BlurredBackground orderStep={3} open={open} />}
				<DrawerToggleIndicator open={open} toggleDrawer={toggleDrawer} />
				<Box
					sx={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: 2 }}
				>
					{isStockCheck ? (
						<>
							<HeaderText variant={"h3"} hasBorder={true}>
								{t("order.step4.header")}
							</HeaderText>
							<DrawerSubHeaderText>{t("order.step4.subheader")}</DrawerSubHeaderText>
						</>
					) : (
						<>
							<HeaderText variant={"h3"}>{t("order.step3.header")}</HeaderText>
							<OrderStep2Arrow />
							<DrawerSubHeaderText>{t("order.step3.subheader")}</DrawerSubHeaderText>
						</>
					)}
				</Box>
			</SwipeableArea>
			<Body
				width={"100%"}
				maxHeight={"1000px"}
				justifyContent={"center"}
				alignItems={"center"}
				display={"flex"}
				flexDirection={"column"}
				sx={{
					...(open && {
						marginTop: "220px",
					}),
					padding: "0 0 120px 0",
				}}
			>
				<OrderStep3Body
					orderDishes={orderDishes}
					orderDishTypes={orderDishTypes}
					stock={stock}
					order={order}
					displayOpenedDoorWarning={displayOpenedDoorWarning}
					resetCountdown={() => resetCountdown()}
				/>
			</Body>
		</>
	);
};

interface OrderStep3BodyProps {
	orderDishes: Dish[];
	orderDishTypes?: LocalDishType[];
	stock: LocalDishType[];
	order: Order | null;
	displayOpenedDoorWarning: boolean;
	resetCountdown: () => void;
}

const OrderStep3Body = (props: OrderStep3BodyProps) => {
	const { t } = useTranslation();
	const { orderDishes, orderDishTypes, order, stock, displayOpenedDoorWarning, resetCountdown } =
		props;

	if (orderDishes.length === 0 && order?.state === TransactionState.Active) {
		return (
			<>
				<ChooseFood />
				{displayOpenedDoorWarning && (
					<OpenedDoorWarningBox resetCountdown={resetCountdown} />
				)}
			</>
		);
	}

	if (orderDishes.length !== 0) {
		return (
			<>
				<StyledDishTypeList
					isLargeOrder={
						orderDishTypes && orderDishTypes?.length > ORDER_STEP_3_SMALL_ORDER_LIMIT
					}
					data-testid="dish-list"
				>
					{stock
						.filter(
							(dishType) =>
								orderDishes.find((dish) => dish?.type?.id === dishType.id) !==
								undefined,
						)
						.map((dishType, index) => (
							<Fragment key={`orderStep3_item_${dishType.id}`}>
								<DishTypeItem
									dishType={dishType}
									type={DishItemDisplayType.ORDER}
									pieces={
										orderDishes.filter((dish) => dish?.type?.id === dishType.id)
											.length
									}
								/>
								{orderDishes.length > 0 && index !== orderDishes.length - 1 && (
									<Divider
										sx={{
											borderColor: "rgba(255, 255, 255, 0.5)",
											margin: "8px 0",
										}}
									/>
								)}
							</Fragment>
						))}
				</StyledDishTypeList>
				{order?.state === TransactionState.StockCheck && (
					<StyledBottomMessage data-testid="stock-check">
						<Typography variant={"promoHeader"}>
							{t("order.step4.header")}...
						</Typography>
						<CircularProgress
							sx={{ margin: "auto", color: "#00396c" }}
							size={"75px"}
							thickness={5}
						/>
					</StyledBottomMessage>
				)}
				{displayOpenedDoorWarning && (
					<OpenedDoorWarningBox resetCountdown={resetCountdown} />
				)}
			</>
		);
	}

	if (order?.state === TransactionState.StockCheck) {
		return (
			<>
				<ProcessingOrderImage
					style={{
						position: "absolute",
						top: "165px",
					}}
				/>
				<StyledBottomMessage data-testid="stock-check">
					<Typography variant={"promoHeader"}>{t("order.step4.header")}...</Typography>
					<CircularProgress
						sx={{ margin: "auto", color: "#00396c" }}
						size={"75px"}
						thickness={5}
					/>
				</StyledBottomMessage>
			</>
		);
	}
};

export const StyledDishTypeList = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isLargeOrder",
})<
	BoxProps & {
		isLargeOrder?: boolean;
	}
>(({ theme, isLargeOrder }) => ({
	...(isLargeOrder && {
		maskImage: "linear-gradient(to bottom, black calc(100% - 45px), transparent 100%)",
		paddingBottom: theme.spacing(5),
	}),
	display: "flex",
	width: "100%",
	height: "100%",
	flexDirection: "column",
	gap: theme.spacing(1),
	overflow: "auto",
}));
