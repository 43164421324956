import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// Box used for Error messages in modals
export const ErrorBox = styled(Box)(({ theme }) => ({
	padding: "16px 20px",
	backgroundColor: theme.palette.error.main,
	borderRadius: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));
