import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Step, StepButton, Stepper, StepperProps, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { TutorialSteps } from "./TutorialSteps";

type TutorialStepperProps = {
	activeStep: number;
	onStepChange: (step: number) => void;
};
export const TutorialStepper = ({ activeStep, onStepChange }: TutorialStepperProps) => {
	const theme = useTheme();

	return (
		<StyledStepper
			activeStep={activeStep}
			connector={
				<ArrowForwardIcon
					sx={{
						color: theme.palette.copper.main,
					}}
					fontSize={"large"}
				/>
			}
		>
			{TutorialSteps.map((step, index) => (
				<Step
					key={`tutorial_step${index + 1}`}
					completed={false}
					onClick={() => onStepChange(index)}
					active={activeStep === index}
				>
					<StepButton onClick={() => onStepChange(index)}>{index + 1}</StepButton>
				</Step>
			))}
		</StyledStepper>
	);
};

const StyledStepper = styled(Stepper, {
	shouldForwardProp: (prop) => prop !== "activeStep",
})<StepperProps & { activeStep: number }>(({ theme, activeStep }) => ({
	width: "420px",
	margin: "auto",
	marginTop: 0,
	marginBottom: theme.spacing(3),
	justifyContent: "space-evenly",
	"& .MuiStepLabel-label": {
		display: "none",
	},
	"& .MuiStepLabel-iconContainer": {
		padding: 0,
	},
	"& .MuiStepIcon-root": {
		width: "56px",
		height: "56px",
		"&.Mui-active": {
			color:
				activeStep === 0
					? theme.palette.copper.main
					: activeStep === 1
					? theme.palette.gold.main
					: activeStep === 2
					? theme.palette.green.main
					: theme.palette.silver.main,
			text: {
				fill: theme.palette.common.black,
				fontWeight: 600,
			},
		},
	},
}));
