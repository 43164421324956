import { useEffect, useRef } from "react";

/**
 * Wraps the setTimeout function in a hook.
 * @param callback Function to call after the timeout
 * @param delay Timeout in milliseconds, pass `null` to clear the timeout
 * @see https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
 * @returns Reference to setTimeout
 */
export const useTimeout = (callback: () => void, delay: number | null) => {
	const timeoutRef = useRef<number | null>(null);
	const savedCallback = useRef(callback);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === "number") {
			timeoutRef.current = window.setTimeout(tick, delay);
			return () => {
				if (timeoutRef.current !== null) {
					window.clearTimeout(timeoutRef.current);
				}
			};
		}
	}, [delay]);

	return timeoutRef;
};
