import { ErrorResponse } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { Breadcrumb } from "@sentry/react";
import { GraphQLError } from "graphql/error";
import { Kind } from "graphql/language";

import { log } from "../../utils/logger";

export const initSentry = () => {
	if (window.MSW_ENABLED === true) {
		return;
	}

	Sentry.init({
		// when undefined, sentry does not send any events
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.MODE,
		normalizeDepth: 6,
		release: `terminal-app@${import.meta.env.APP_VERSION}+${import.meta.env.VITE_BUILD_NUMBER}`,
		transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			Sentry.captureConsoleIntegration({
				levels: ["error", "warn"],
			}),
		],
		initialScope: {
			tags: {
				fridgeId: localStorage.getItem("fridgeId"),
				tabletType: localStorage.getItem("tabletType"),
			},
			contexts: {
				fridgeInfo: {
					fridgeId: localStorage.getItem("fridgeId"),
					tabletType: localStorage.getItem("tabletType"),
				},
			},
		},
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which
		// URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost"],
		// Session Replay
		replaysSessionSampleRate: 0.1,
		// This sets the sample rate at 10%. You may want to change it to 100%
		// while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0,
		// If you're not already sampling the entire session,
		// change the sample rate to 100% when sampling sessions where errors occur.
	});
};

type SentryErrorEnrichedData = {
	kind: string;
	query: Kind.DOCUMENT;
	variables: Record<string, string | number>;
};

// Collect data about fridge and GQL query for Sentry scope
const getErrorData = (error: ErrorResponse): SentryErrorEnrichedData => ({
	kind: error.operation.operationName,
	query: error.operation.query.kind,
	variables: error.operation.variables,
});

const getBreadcrumb = (graphQLError: GraphQLError): Breadcrumb => ({
	category: "query-path",
	message: graphQLError.path?.join(" > "),
});

export const captureApolloError = (
	error: Error,
	errorData: SentryErrorEnrichedData,
	extraData?: { [key: string]: unknown },
) => {
	Sentry.withScope((scope) => {
		if (errorData.kind) {
			scope.setTag("kind", errorData.kind);
		}
		if (errorData.query) {
			scope.setExtra("query", errorData.query);
		}
		if (errorData.variables) {
			scope.setExtra("variables", errorData.variables);
		}
		if (extraData) {
			Object.keys(extraData).forEach((key) => {
				scope.setExtra(key, extraData[key]);
			});
		}
		if (error instanceof GraphQLError && error?.path) {
			scope.addBreadcrumb(getBreadcrumb(error));
		}
		Sentry.captureException(error);
	});
};

export const handleSendApolloErrorToSentry = (error: ErrorResponse) => {
	const errorData = getErrorData(error);
	log("Application error", "error", error.response);

	if (error.graphQLErrors) {
		for (const graphQLError of error.graphQLErrors) {
			captureApolloError({ ...graphQLError, name: graphQLError.message }, errorData);
		}
	}

	if (error.networkError) {
		captureApolloError(error.networkError, errorData);
	}
};
