import EngineeringIcon from "@mui/icons-material/Engineering";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { PaymentMethodType } from "gql/graphql";
import { batch } from "react-redux";
import { setDoorState, setLockState } from "slices/doorSlice";
import { setOrder } from "slices/transactionSlice";
import fridgeMock from "tests/mocks/data/fridge.json";

import { cleanGQLData } from "components/global/debug-utils";
import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { ActionButton } from "components/primitives/ActionBarButton";
import { useDeviceContext } from "contexts/DeviceContext";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { handleSetInitialData } from "hooks/device";
import { useAppDispatch } from "hooks/utilsHooks/storeHooks";
import { DoorState, FridgeTransaction, LockState, Order, TransactionState } from "types";
import { LocalFridgeState } from "types/mainTypes";

import { getDishRemovedData } from "../../../tests/integration/mocks/data/dishRemoved.data";
import { getFinishedTransaction } from "../../../tests/integration/mocks/data/finishedTransaction.data";
import { WSDataVariant } from "../../../tests/integration/mocks/types";
import { BasicModal } from "./modals/BasicModal";

const MOCKED_DATA = {
	smallOrder: cleanGQLData<FridgeTransaction>(getDishRemovedData(WSDataVariant.SMALL_ORDER)),
	largeOrder: cleanGQLData<FridgeTransaction>(getDishRemovedData(WSDataVariant.LARGE_ORDER)),
	emptyOrder: cleanGQLData<FridgeTransaction>(getFinishedTransaction(WSDataVariant.EMPTY)),
	fridge: cleanGQLData<LocalFridgeState>(fridgeMock.data.fridge),
	emptyFridge: (() => {
		const cleanedData = cleanGQLData<LocalFridgeState>(fridgeMock.data.fridge);
		cleanedData.dishes.items = null;
		return cleanedData;
	})(),
};

export const Debug = () => {
	return (
		<>
			<BasicModal
				modalContent={<DebugModal />}
				closable={true}
				fullHeight={true}
				toggleButton={
					<ActionButton
						sx={{
							width: "80px",
							height: "68px",
						}}
					>
						<EngineeringIcon fontSize={"large"} color={"disabled"} />
					</ActionButton>
				}
			/>
		</>
	);
};

const DebugModalButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.copper.main,
	color: theme.palette.primary.contrastText,
	lineHeight: "1.2rem",
	padding: theme.spacing(1),
	minHeight: "50px",
	height: "100%",
	width: "100%",
	"&:hover": {
		backgroundColor: theme.palette.primary.dark,
	},
}));

DebugModalButton.defaultProps = {
	variant: "contained",
	size: "small",
};

const DebugModal = () => {
	const { openSnackbarWithContent } = useSnackbarContext();
	const dispatch = useAppDispatch();
	const { handleRefetchDishTypes } = useDeviceContext();

	const handleDisplayError = () => {
		throw new Error("This is test error");
	};

	const handleDisplayMockSnackbar = () => {
		openSnackbarWithContent(
			<SnackbarContent
				variant="welcome"
				user={{
					name: 'John "Kolda" Doe',
					id: "123",
				}}
			/>,
		);
	};

	const handleFridgeSwitch = (fridgeId: string | null) => {
		if (!fridgeId) {
			return;
		}
		localStorage.setItem("fridgeId", fridgeId);
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("fridgeId", fridgeId);
		window.location.search = searchParams.toString();
	};

	const handleOpenFridge = () => {
		batch(() => {
			dispatch(setLockState(LockState.Unlocked));
			dispatch(setOrder(MOCKED_DATA.emptyOrder.orders?.last ?? null));
		});
	};

	const handleTakeSmallOrder = () => {
		batch(() => {
			dispatch(setDoorState(DoorState.Opened));
			dispatch(setLockState(LockState.Locked));
			dispatch(setOrder(MOCKED_DATA.smallOrder.orders?.last ?? null));
		});
	};

	const handleTakeLargeOrder = () => {
		batch(() => {
			dispatch(setDoorState(DoorState.Opened));
			dispatch(setLockState(LockState.Locked));
			dispatch(setOrder(MOCKED_DATA.largeOrder.orders?.last ?? null));
		});
	};

	const handleStartStockCheck = (smallOrder: boolean | null) => {
		const order =
			smallOrder === null
				? MOCKED_DATA.emptyOrder
				: smallOrder
				? MOCKED_DATA.smallOrder
				: MOCKED_DATA.largeOrder;
		batch(() => {
			dispatch(setDoorState(DoorState.Closed));
			dispatch(setLockState(LockState.Locked));
			dispatch(
				setOrder({
					...(order.orders?.last as Order),
					state: TransactionState.StockCheck,
				}),
			);
		});
	};

	const handleFinishLargeOrder = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.largeOrder.orders?.last as Order),
					state: TransactionState.Finished,
				}),
			);
		});
	};

	const handleFinishLargeOrderMobilePayment = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.largeOrder.orders?.last as Order),
					state: TransactionState.Finished,
					payment: {
						method: {
							id: "123",
							type: PaymentMethodType.NativePay,
						},
					},
				}),
			);
		});
	};

	const handleFinishSmallOrderMobilePayment = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.smallOrder.orders?.last as Order),
					state: TransactionState.Finished,
					payment: {
						method: {
							id: "123",
							type: PaymentMethodType.NativePay,
						},
					},
				}),
			);
		});
	};

	const handleFinishSmallOrderTopUpPayment = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.smallOrder.orders?.last as Order),
					state: TransactionState.Finished,
					payment: {
						method: {
							id: "123",
							type: PaymentMethodType.TopUp,
						},
					},
				}),
			);
		});
	};

	const handleFinishSmallOrder = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.smallOrder.orders?.last as Order),
					state: TransactionState.Finished,
				}),
			);
		});
	};

	const handleFinishEmptyOrder = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.emptyOrder.orders?.last as Order),
					state: TransactionState.Finished,
				}),
			);
		});
	};

	const handleFinishOrderWithoutQrCode = () => {
		batch(() => {
			dispatch(
				setOrder({
					...(MOCKED_DATA.emptyOrder.orders?.last as Order),
					state: TransactionState.Finished,
					access: null,
				}),
			);
		});
	};

	return (
		<Box padding={2} sx={(theme) => ({ marginTop: theme.spacing(10) })}>
			<Grid container spacing={2} xs={12}>
				<Grid xs={12}>
					<DebugModalButton
						onClick={() => handleSetInitialData(MOCKED_DATA.emptyFridge, dispatch)}
					>
						Empty Fridge
					</DebugModalButton>
				</Grid>

				<Grid xs={12} container spacing={2}>
					<Grid xs={12}>
						<DebugModalButton
							onClick={() => handleSetInitialData(MOCKED_DATA.fridge, dispatch)}
						>
							Order Step 1
						</DebugModalButton>
					</Grid>
					<Grid xs={12}>
						<DebugModalButton onClick={() => handleOpenFridge()}>
							Order Step 2
						</DebugModalButton>
					</Grid>

					<Grid xs={12} container spacing={2}>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleOpenFridge()}>
								Order Step 3 - Empty order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleTakeSmallOrder()}>
								Order Step 3 - small order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleTakeLargeOrder()}>
								Order Step 3 - large order
							</DebugModalButton>
						</Grid>
					</Grid>
					<Grid xs={12} container>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleStartStockCheck(null)}>
								Order Step 4 - Empty order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleStartStockCheck(true)}>
								Order Step 4 - Small order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleStartStockCheck(false)}>
								Order Step 4 - Large order
							</DebugModalButton>
						</Grid>
					</Grid>

					<Grid xs={12} container>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleFinishEmptyOrder()}>
								Order Step 5 - Empty order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleFinishSmallOrder()}>
								Order Step 5 - Small order
							</DebugModalButton>
						</Grid>
						<Grid xs={4}>
							<DebugModalButton onClick={() => handleFinishLargeOrder()}>
								Order Step 5 - Large order
							</DebugModalButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={6}>
						<DebugModalButton onClick={() => handleFinishSmallOrderMobilePayment()}>
							Order Step 5 - Mobile Payment - Small order
						</DebugModalButton>
					</Grid>
					<Grid xs={6}>
						<DebugModalButton onClick={() => handleFinishLargeOrderMobilePayment()}>
							Order Step 5 - Mobile Payment - Large order
						</DebugModalButton>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={12}>
						<DebugModalButton onClick={() => handleFinishSmallOrderTopUpPayment()}>
							Order Step 5 - TopUp Payment - Small order
						</DebugModalButton>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={12}>
						<DebugModalButton onClick={() => handleFinishOrderWithoutQrCode()}>
							Order Step 5 - Finish order without QR code
						</DebugModalButton>
					</Grid>
				</Grid>
				<Grid xs={12} container>
					<Grid xs={6}>
						<DebugModalButton onClick={() => handleDisplayMockSnackbar()}>
							Display snackbar
						</DebugModalButton>
					</Grid>
					<Grid xs={6}>
						<DebugModalButton onClick={() => handleDisplayError()}>
							Display Error
						</DebugModalButton>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Grid xs={12}>
						<Typography id="demo-radio-buttons">Connected fridge</Typography>
						<Select
							value={localStorage.getItem("fridgeId")}
							onChange={(e) => handleFridgeSwitch(e.target.value)}
						>
							<MenuItem value={import.meta.env.VITE_FRIDGE_1_ID}>Fridge 1</MenuItem>
							<MenuItem value={import.meta.env.VITE_FRIDGE_2_ID}>Fridge 2</MenuItem>
							<MenuItem value={import.meta.env.VITE_REVIEW_FRIDGE_ID}>
								Virtual Review Fridge
							</MenuItem>
							<MenuItem value={import.meta.env.VITE_VIRTUAL_FRIDGE_ID}>
								Virtual DEV fridge
							</MenuItem>
							<MenuItem value={import.meta.env.VITE_DEV_CARRY_FRIDGE_ID}>
								DEV carry fridge
							</MenuItem>
						</Select>
					</Grid>
					<Grid xs={4}>
						<DebugModalButton onClick={() => handleRefetchDishTypes()}>
							Refetch prices
						</DebugModalButton>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
