import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";

export const StyledDrawerSubHeaderText = styled(Typography)(({ theme }) => ({
	fontSize: "32px",
	fontWeight: 500,
	lineHeight: "40px",
	marginTop: "-10px",
	color: theme.palette.common.white,
}));

export const DrawerSubHeaderText: FC<TypographyProps> = (props) => {
	return (
		<StyledDrawerSubHeaderText variant={"body2"}>{props.children}</StyledDrawerSubHeaderText>
	);
};
