import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { usePriceFormat } from "hooks/formatting/usePriceFormat";
import { useOrder } from "hooks/useOrder";
import { TransactionState } from "types";

type DrawerOrderSummaryRowProps = {
	orderStep: number;
};

export const DrawerOrderSummaryRow = ({ orderStep }: DrawerOrderSummaryRowProps) => {
	const { formatPrice } = usePriceFormat();
	const { order, orderDishes } = useOrder();
	const { price, state: orderState } = order || {};

	const { t } = useTranslation();

	if (!order) {
		return null;
	}

	return (
		<>
			{order && orderState === TransactionState.Active && orderStep === 3 ? (
				<Box display={"flex"}>
					{price && (
						<Typography variant={"h5"} fontWeight={900}>
							{price.total?.current &&
								formatPrice(price.total.current, price.total?.currency)}
						</Typography>
					)}
					<Typography variant={"h6"} color={"white"} marginLeft={1}>
						({t("order.dishCount", { count: orderDishes.length })})
					</Typography>
				</Box>
			) : null}
		</>
	);
};
