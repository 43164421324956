import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ErrorIcon from "assets/icons/errorIcon.svg";
import OtherIcon from "assets/icons/otherIcon.svg";
import SadFaceSquared from "assets/icons/sadFaceSquared.svg";
import UnchargedMealIcon from "assets/icons/unchargedMealIcon.svg";
import { ModalContentWrapper } from "components/primitives/ModalContentWrapper";
import { ModalTitle } from "components/primitives/ModalTitle";
import { useCountdown } from "hooks/timers";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { OrderFeedbackReasonType } from "types";

const ISSUE_MAP = [
	{
		icon: <ErrorIcon />,
		label: "modals.reportIssueModal.issue.generalError",
		orderFeedbackReason: OrderFeedbackReasonType.SOMETHING_WENT_WRONG,
	},
	{
		icon: <SadFaceSquared />,
		label: "modals.reportIssueModal.issue.overcharged",
		orderFeedbackReason: OrderFeedbackReasonType.OVERCHARGED,
	},
	{
		icon: <UnchargedMealIcon />,
		label: "modals.reportIssueModal.issue.mealNotCharged",
		orderFeedbackReason: OrderFeedbackReasonType.NOT_CHARGED,
	},
	{
		icon: <OtherIcon />,
		label: "modals.reportIssueModal.issue.other",
		orderFeedbackReason: OrderFeedbackReasonType.OTHER,
	},
];

type ReportIssueModalContentProps = {
	onClose: () => void;
	onSendFeedbackAction: (reason: OrderFeedbackReasonType | null) => Promise<void>;
};

export const ReportIssueModalContent = ({
	onClose,
	onSendFeedbackAction,
}: ReportIssueModalContentProps) => {
	const { t } = useTranslation();
	const { trackReportIssueFeedback } = useMixpanelTrackers();

	const { countdown } = useCountdown(20);

	const handleReportIssueClick = (reason: OrderFeedbackReasonType) => {
		trackReportIssueFeedback(reason);
		onSendFeedbackAction(reason);
	};

	useEffect(() => {
		if (countdown === 1) {
			onSendFeedbackAction(null);
		}
	}, [countdown]);

	return (
		<ModalContentWrapper
			sx={{
				alignItems: "center",
			}}
		>
			<ModalTitle variant={"promoHeader"}>{t("modals.reportIssueModal.title")}</ModalTitle>
			<Typography variant={"promoText"}>
				{t("modals.reportIssueModal.description")}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					margin: "auto 0",
					width: "100%",
				}}
			>
				{ISSUE_MAP.map((item) => {
					return (
						<StyledButton
							startIcon={item.icon}
							variant={"contained"}
							fullWidth
							key={`feedback_${item.orderFeedbackReason}`}
							onClick={() => {
								handleReportIssueClick(item.orderFeedbackReason);
							}}
						>
							<Typography fontSize={"24px"} fontWeight={500}>
								{t(item.label)}
							</Typography>
						</StyledButton>
					);
				})}
			</Box>
			<CancelButton
				onClick={() => {
					onClose();
				}}
			>
				<Typography variant={"button"}>{t("common.close")}</Typography>
			</CancelButton>
			<Typography
				sx={{
					color: (theme) => theme.palette.common.grey[4],
				}}
				variant={"headlineMedium"}
			>
				{t("modals.reportIssueModal.closeWarning", { countdown: countdown })}
			</Typography>
		</ModalContentWrapper>
	);
};
const StyledButton = styled(Button)(() => ({
	color: "black",
	textTransform: "unset",
	fontWeight: "500",
	width: "100%",
	display: "flex",
	justifyContent: "flex-start",
	gap: 20,
	padding: "20px 24px",
	backgroundColor: "rgba(0,0,0,0.05)",
	boxShadow: "none",
	"& .MuiButton-startIcon": {
		margin: 0,
	},
	"& svg path": {
		fill: "black",
	},
}));
const CancelButton = styled(Button)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	marginBottom: theme.spacing(2),
	backgroundColor: theme.palette.darkBackground.default,
	color: theme.palette.common.black,
	padding: "16px 24px",
}));
