import mixpanel, { Dict } from "mixpanel-browser";
import { FC, PropsWithChildren, createContext, useContext, useEffect } from "react";
import { v4 as uuid } from "uuid";

import { MixpanelTabletTypeEnum } from "types/mixpanelTypes";

import { canSentEventsToMixpanel } from "../utils/analytics/mixpanel";
import { getFridgeId } from "../utils/browser/localStorage";
import { log } from "../utils/logger";

/**
 * Represents the properties for the AnalyticsContext.
 */
export type AnalyticsContextProps = {
	identify: (userId: string) => void;
	people: {
		set: (ata: Dict) => void;
	};
	reset: () => void;
	track: (name: string, properties: Dict) => void;
};

/**
 * Represents the context for analytics.
 **/
export const AnalyticsContext = createContext<AnalyticsContextProps>({
	track: () => null,
	reset: () => null,
	identify: () => null,
	people: {
		set: () => null,
	},
});

/**
 * AnalyticsContextProvider component
 */
export const AnalyticsContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const mixpanelInstance = mixpanel;
	const fridgeId = getFridgeId();

	mixpanelInstance.init(import.meta.env.VITE_MIXPANEL_PROJECT_ID, {
		property_blacklist: [
			"$city",
			"$region",
			"mp_country_code",
			"$browser",
			"$browser_version",
			"$device",
			"$current_url",
			"$initial_referrer",
			"$initial_referring_domain",
			"$os",
			"mp_lib",
			"$referrer",
			"$referring_domain",
			"$screen_height",
			"$screen_width",
			"$search_engine",
			"mp_keyword",
		],
	});

	useEffect(() => {
		mixpanelInstance.register({
			$session_id: uuid(),
			$source_of_stats: "Tablet",
			$tabletType: MixpanelTabletTypeEnum.Master,
			$fridge_id: fridgeId,
		});
	}, []);

	const actions = {
		track: (name: string, props: Dict) => {
			const useMixpanel = canSentEventsToMixpanel();
			if (useMixpanel) {
				mixpanelInstance.track(name, {
					...props,
				});
			} else {
				// eslint-disable-next-line no-console
				log("Not master tablet, not tracking", undefined, name, props);
			}
		},
		reset: () => {
			mixpanelInstance.reset();
			mixpanelInstance.register({
				$session_id: uuid(),
				$source_of_stats: "Tablet",
				$tabletType: MixpanelTabletTypeEnum.Master,
				$fridge_id: fridgeId,
			});
		},
		identify: (userId: string) => {
			mixpanelInstance.identify(userId);
		},
		people: {
			set: (data: { [key: string]: string | number }) => {
				mixpanelInstance.people.set(data);
			},
		},
	};

	return (
		<AnalyticsContext.Provider
			value={{
				track: actions.track,
				reset: actions.reset,
				identify: actions.identify,
				people: actions.people,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

/**
 * Retrieves the analytics context from the React Context API.
 *
 * @return {Object} The analytics context.
 */
export const useAnalyticsContext = () => {
	return useContext(AnalyticsContext);
};
