import {
	FC,
	PropsWithChildren,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useDeviceContext } from "./DeviceContext";

type ModalContextProps = {
	modal: ReactNode;
	setModal: (modalNode: ReactNode) => void;
};

export const ModalContext = createContext<ModalContextProps>({
	modal: null,
	setModal: (modal) => modal,
});

export const useModalContext = () => {
	return useContext(ModalContext);
};

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [modal, setModal] = useState<ReactNode>(null);
	const { appConnected } = useDeviceContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!appConnected) {
			navigate("/");
		}
	}, [appConnected]);

	return (
		<ModalContext.Provider value={{ modal, setModal }}>
			{children}
			{modal}
		</ModalContext.Provider>
	);
};
