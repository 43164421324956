import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { PriceWrapper } from "components/global/components/PriceWrapper";
import { DishTypeImage } from "components/primitives/DishTypeImage";
import { usePriceFormat } from "hooks/formatting/usePriceFormat";
import { DishItemDisplayType, LocalDishType } from "types/mainTypes";

export const OrderListDishTypeItem = ({
	name,
	pieces,
	dishType,
}: {
	name: { header: string; subHeader: string } | null;
	pieces: number | undefined;
	dishType: LocalDishType;
}) => {
	const { formatPrice, parsePrice } = usePriceFormat();

	const parsedPrice = parsePrice(dishType.price?.current ?? "0");

	return (
		<StyledOrderDishItem>
			<DishTypeImage imageUrl={dishType.image?.url} className={"order"} />
			<StyledOrderDishItemContent>
				<Typography variant={"mealMain"} color={"white"} component={"p"}>
					{name?.header}
				</Typography>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Box display={"flex"} alignItems={"center"}>
						<Typography variant={"mealMain"}>{pieces}</Typography>
						<Typography variant={"mealMain"} marginInline={"8px"}>
							&times;
						</Typography>
						<PriceWrapper price={dishType.price} type={DishItemDisplayType.ORDER} />
					</Box>
					{dishType.price?.current && pieces && (
						<Typography variant={"mealMain"}>
							{formatPrice(parsedPrice * pieces, dishType.price.currency)}
						</Typography>
					)}
				</Box>
			</StyledOrderDishItemContent>
		</StyledOrderDishItem>
	);
};

const StyledOrderDishItemContent = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	marginLeft: "70px",
	width: "100%",
	justifyContent: "space-between",
}));

const StyledOrderDishItem = styled(Box)(({ theme }) => ({
	height: "84px",
	display: "flex",
	position: "relative",
	borderRadius: theme.shape.borderRadius,
}));
