import { styled } from "@mui/material/styles";
import React from "react";

import { QuickFilters } from "components/filters";

/**
 * Main header component with filters and quick filters
 * @constructor
 */
export const Header = () => {
	return (
		<StyledHeader>
			<QuickFilters />
		</StyledHeader>
	);
};

const StyledHeader = styled("header")(() => ({
	position: "relative",
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "8px 0",
	marginBottom: "16px",
}));
