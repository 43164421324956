import { useCallback } from "react";

import { useAppContext } from "contexts/AppContext";

const FALLBACK_CURRENCY = "CZK";
const DEFAULT_DECIMAL_PLACES = 2;

export const usePriceFormat = () => {
	const { locale } = useAppContext();

	const parsePrice = (price: string): number => {
		return Number.parseFloat(price);
	};

	const formatPrice = useCallback(
		(price?: string | number | null, currency?: string | null): string => {
			if (!price) {
				return "";
			}

			const actualPrice = typeof price === "string" ? parsePrice(price) : price;

			return new Intl.NumberFormat(locale, {
				style: "currency",
				currency: currency ?? FALLBACK_CURRENCY,
				maximumFractionDigits: DEFAULT_DECIMAL_PLACES,
			}).format(actualPrice);
		},
		[locale],
	);

	return { formatPrice, parsePrice };
};
