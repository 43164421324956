import { Dispatch } from "react";

import { AllergenType } from "types";
import { AppContextAction, LocalDishType } from "types/mainTypes";

/**
 * Sets the highlighted dish type ID in the app context.
 *
 * @param {string | null} dishTypeId - The ID of the dish type to be highlighted.
 *    Pass `null` to remove the highlight.
 * @param {Dispatch<AppContextAction>} dispatch - The dispatch function from the app context.
 */
export const handleDispatchHighlightedDishTypeId = (
	dishTypeId: string | null,
	dispatch: Dispatch<AppContextAction>,
) => {
	dispatch({
		type: "SET_OPENED_DETAIL_DISH_TYPE_ID",
		payload: dishTypeId,
	});
};
/**
 * Filters the available dish types based on the given parameters.
 *
 * @param {LocalDishType[]} fridgeDishTypes - The dish types in the fridge.
 * @param {string[]} availableDishTypes - The available dish types.
 * @param {AllergenType[]} allergens - The allergens to filter.
 * @returns {LocalDishType[]} - The filtered available dish types.
 */
export const filterAvailableDishTypes = (
	fridgeDishTypes: LocalDishType[],
	availableDishTypes: string[],
	allergens: AllergenType[],
): LocalDishType[] => {
	if (fridgeDishTypes) {
		return fridgeDishTypes.filter(
			(dishType) =>
				availableDishTypes.includes(dishType.id) &&
				!allergens.some(
					(a: AllergenType) => dishType.allergens && dishType.allergens.includes(a),
				),
		);
	} else {
		return [];
	}
};
/**
 * Filters the available dish type IDs based on the given parameters.
 *
 * @param {LocalDishType[]} fridgeDishTypes - The dish types in the fridge.
 * @param {string[]} availableDishTypes - The available dish type names.
 * @param {AllergenType[]} allergens - The allergens to consider when filtering dish types.
 * @returns {string[]} - The filtered available dish type IDs.
 */
export const filterAvailableDishTypeIds = (
	fridgeDishTypes: LocalDishType[],
	availableDishTypes: string[],
	allergens: AllergenType[],
): string[] => {
	return filterAvailableDishTypes(fridgeDishTypes, availableDishTypes, allergens).map(
		(dishType) => dishType.id,
	);
};
