export function log(msg: string, severity?: "error" | "warn" | "info", ...args: unknown[]) {
	if (severity === "error") {
		// eslint-disable-next-line no-console
		console.error(`[APP] [ERROR] ${msg}`, ...args);
	} else if (severity === "warn") {
		// eslint-disable-next-line no-console
		console.warn(`[APP] [WARNING] ${msg}`, ...args);
	} else if (severity === "info") {
		// eslint-disable-next-line no-console
		console.info(`[APP] [INFO] ${msg}`, ...args);
	} else {
		// eslint-disable-next-line no-console
		console.log(`[APP] ${msg}`, ...args);
	}
}
