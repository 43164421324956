import { TypographyStyle, createTheme } from "@mui/material";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
	interface Palette {
		copper: Palette["primary"];
		gold: Palette["primary"];
		green: Palette["primary"];
		silver: Palette["primary"];
		darkBackground: Palette["background"];
		black: Palette["primary"];
		common: CommonColors;
	}

	interface PaletteOptions {
		copper?: PaletteOptions["primary"];
		gold?: PaletteOptions["primary"];
		green?: PaletteOptions["primary"];
		silver?: PaletteOptions["primary"];
		darkBackground?: PaletteOptions["background"];
		black?: PaletteOptions["primary"];
	}

	interface PaletteBackground {
		darkBackground: string;
	}

	interface CommonColors {
		black: string;
		white: string;
		grey: {
			[key: number]: string;
		};
	}

	interface PaletteBackgroundOptions {
		darkBackground: string;
	}

	interface TypographyVariants {
		badge: CSSProperties;
		cta: CSSProperties;
		ctaSubtext: CSSProperties;
		ctaSubtextBold: CSSProperties;
		headlineMedium: CSSProperties;
		filter: CSSProperties;
		promoHeader: CSSProperties;
		promoText: CSSProperties;
		price: CSSProperties;
	}

	interface TypographyVariantsOptions {
		badge?: TypographyStyle;
		cta?: TypographyStyle;
		ctaSubtext?: TypographyStyle;
		ctaSubtextBold?: TypographyStyle;
		headlineMedium?: TypographyStyle;
		filter?: TypographyStyle;
		mealMain?: TypographyStyle;
		promoHeader?: TypographyStyle;
		promoText?: TypographyStyle;
		price?: TypographyStyle;
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		badge: true;
		cta: true;
		ctaSubtext: true;
		ctaSubtextBold: true;
		filter: true;
		headlineMedium: true;
		mealMain: true;
		promoHeader: true;
		promoText: true;
		price: true;
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		black: true;
	}
}

const EAT_PERFECT_THEME = createTheme({
	palette: {
		error: {
			main: "#CC474FE5",
		},
		copper: {
			main: "#C99272E5",
			dark: "#1E1E1E7F",
		},
		gold: {
			main: "#B69A55E5",
		},
		green: {
			main: "#8BA08DE5",
		},
		silver: {
			main: "#89989AE5",
		},

		darkBackground: {
			default: "#1E1E1E26",
		},
		black: {
			main: "#000000",
		},
		common: {
			grey: {
				4: "#B6B6B6",
				9: "#FFFFFF26",
			},
		},
	},
	shape: {
		borderRadius: 16,
	},
	typography: {
		fontFamily: "Montserrat, sans-serif",
		badge: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "1rem",
			fontWeight: 500,
			fontStyle: "normal",
			lineHeight: "1rem",
		},
		cta: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "3rem",
			fontWeight: 900,
			fontStyle: "normal",
			lineHeight: "3rem",
			textTransform: "uppercase",
		},
		ctaSubtextBold: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "2rem",
			fontWeight: 900,
			fontStyle: "normal",
			lineHeight: "2.5rem",
		},
		ctaSubtext: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "2rem",
			fontWeight: 500,
			fontStyle: "normal",
			lineHeight: "2.5rem",
		},
		headlineMedium: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "1.5rem",
			fontWeight: 500,
			fontStyle: "normal",
			lineHeight: "1.75rem",
		},
		filter: {
			fontFamily: "Montserrat, sans-serif",
			fontSize: "1.5rem",
			fontWeight: 600,
			lineHeight: "1.75rem",
		},
		mealMain: {
			fontFamily: "Montserrat",
			fontSize: "1.75rem",
			fontStyle: "normal",
			fontWeight: 600,
			lineHeight: "2.5rem",
		},
		price: {
			fontSize: "2rem",
			fontWeight: 600,
			lineHeight: "2.25rem",
		},
		promoHeader: {
			fontSize: "2.5rem",
			fontWeight: 600,
			fontStyle: "normal",
			lineHeight: "3rem",
		},
		promoText: {
			fontSize: "1.75rem",
			fontWeight: 500,
			fontStyle: "normal",
			lineHeight: "2.5rem",
		},
		button: {
			fontWeight: 900,
			fontSize: "1.75rem",
			lineHeight: "2rem",
			textTransform: "uppercase",
		},
	},
});

export default EAT_PERFECT_THEME;
