import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { LocalFridgeSegment } from "../types/mainTypes";
import { getShortenedFridgeId } from "../utils/utils";

type FridgeInfoSlice = {
	id: string | null;
	segments: LocalFridgeSegment[];
	location: string | null;
};

const initialState: FridgeInfoSlice = {
	id: "",
	segments: [],
	location: null,
};

export const fridgeInfoSlice = createSlice({
	name: "fridgeInfo",
	initialState,
	reducers: {
		setFridgeId: (state, { payload }: PayloadAction<string>) => {
			state.id = payload;
		},
		setSegments: (state, { payload }: PayloadAction<LocalFridgeSegment[]>) => {
			state.segments = payload;
		},
		setLocation: (state, { payload }: PayloadAction<string>) => {
			state.location = payload;
		},
	},
});

export const { setFridgeId, setSegments, setLocation } = fridgeInfoSlice.actions;
export const selectFridgeId = (state: RootState) => state.fridgeInfo.id;

export const selectShortFridgeId = (state: RootState) => getShortenedFridgeId(state.fridgeInfo.id);
export const selectFridgeSegments = (state: RootState) => state.fridgeInfo.segments;
export const selectFridgeLocation = (state: RootState) => state.fridgeInfo.location;

export default fridgeInfoSlice.reducer;
