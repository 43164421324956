import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	BoxProps,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { RefObject, useEffect, useRef } from "react";
import { createSplitDishName } from "utils/utils";

import { OrderListDishTypeItem } from "components/orderSteps/components/OrderListDishTypeItem";
import { DishTypeImage } from "components/primitives/DishTypeImage";
import { useAppContext } from "contexts/AppContext";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { useOrder } from "hooks/useOrder";
import { TransactionState } from "types";
import { DishItemDisplayType, LocalDishType } from "types/mainTypes";

import { DishTypeDetailTabs } from "./components/DishTypeDetailTabs";
import { InfoRow } from "./components/InfoRow";

type DishTypeItemProps = {
	dishType: LocalDishType;
	type: DishItemDisplayType;
	pieces?: number;
	isOpen?: boolean;
	onOpen?: (ref: RefObject<HTMLDivElement>) => void;
};

export const DishTypeItem = ({
	dishType,
	type,
	pieces,
	isOpen = false,
	onOpen,
}: DishTypeItemProps) => {
	const { description, image } = dishType;
	const { selectedLanguage } = useAppContext();
	const name = createSplitDishName(description?.[selectedLanguage]?.name);
	const { trackOpenDishItemDetail } = useMixpanelTrackers();

	const { order } = useOrder();
	const ref = useRef<HTMLDivElement>(null);

	const handleAccordionOpen = (e: React.SyntheticEvent<Element, Event>) => {
		if (onOpen && e && type !== DishItemDisplayType.ORDER) {
			trackOpenDishItemDetail(isOpen, dishType, name);
			onOpen(ref);
		}
	};

	useEffect(() => {
		if (isOpen && onOpen && order?.state === TransactionState.Finished) {
			onOpen(ref);
		}
	}, [order?.state]);

	if (type === DishItemDisplayType.LIST) {
		return (
			<StyledAccordion
				key={`accordion_${dishType.id}`}
				onChange={(e) => {
					handleAccordionOpen(e);
				}}
				expanded={isOpen}
				TransitionProps={{ unmountOnExit: true, timeout: 200 }}
				ref={ref}
			>
				<StyledAccordionSummary className={isOpen ? "expanded" : ""}>
					<DishTypeImage imageUrl={image?.url} className={isOpen ? "expanded" : ""} />
					<HeaderWrapper className={isOpen ? "expanded" : ""}>
						<Header className={isOpen ? "expanded" : ""}>{name?.header}</Header>
						<Subheader expanded={isOpen}>{name?.subHeader || <>&nbsp;</>}</Subheader>
						<InfoRow dishType={dishType} type={type} pieces={pieces} />
					</HeaderWrapper>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<DishTypeDetailTabs dishType={dishType} />
				</StyledAccordionDetails>
			</StyledAccordion>
		);
	} else {
		return <OrderListDishTypeItem name={name} dishType={dishType} pieces={pieces} />;
	}
};

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
	padding: "0px 20px 0 0",
	alignItems: "flex-start",
	transition: "all 200ms linear",
	"&.expanded": {
		minHeight: "240px",
	},
}));

const Header = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.white,
	fontWeight: 600,
	fontSize: "1.75rem",
	lineHeight: "40px",

	"&.expanded": {
		fontSize: "2.25rem",
		transform: "font",
		transition: "all 200ms linear",
	},
}));

const Subheader = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "expanded",
})<BoxProps & { expanded: boolean }>(({ theme, ...props }) => ({
	color: theme.palette.common.white,
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.75rem",
	...(!props.expanded && {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		width: "100%",
		overflow: "hidden",
	}),
	// Set min height to keep the wrapper proportions when there is no subtitle
	minHeight: "28px",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	width: "100%",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: "#353535",
	transition: "all 200ms linear",

	"&.MuiAccordion-root:before": {
		display: "none",
	},
	"& .MuiAccordionSummary-content": {
		width: "100%",
		margin: 0,
		transition: "all 200ms linear",
	},

	"& .MuiAccordionSummary-root": {
		transition: "all 200ms linear",
	},

	"& .MuiCollapse-root": {
		overflow: "hidden",
		transition: "all 200ms linear",
	},
}));

const HeaderWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	width: "calc(100% - 120px)",
	marginLeft: "auto",
	transition: "all 200ms linear",
	padding: "20px 0",

	"&.expanded": {
		padding: "25px 0",
		width: "calc(100% - 180px)",
	},
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	padding: "8px 20px 40px",
	color: theme.palette.common.white,

	"&.MuiAccordionDetails-root": {
		marginTop: 0,
	},
}));
