import { Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";

export const SnackbarSubHeader: FC<TypographyProps> = ({ children }) => {
	return (
		<Typography variant={"body1"} fontSize={"1.75rem"} lineHeight={"3rem"} marginBottom={0}>
			{children}
		</Typography>
	);
};
