import { Box, Collapse } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDishTypeTextValue } from "utils/utils";

import { useAppContext } from "contexts/AppContext";
import { LocalDishType } from "types/mainTypes";

export const CompositionTab = ({ dishType }: { dishType: LocalDishType }) => {
	const { selectedLanguage } = useAppContext();
	const { t } = useTranslation();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleChange = () => {
		setIsExpanded((prev) => !prev);
	};

	// We have toi temporarily split the composition saved in DB as
	// it also contains the cooking instructions
	const composition = getDishTypeTextValue(dishType, selectedLanguage, "composition");

	return (
		<Box display={"flex"} justifyContent={"space-between"} paddingTop={2}>
			<StyledCompositionWrapper>
				{/* TODO: Uncomment when we have real data */}
				{/* <NutritionTable nutritionValues={MOCK_NUTRITION_VALUES} /> */}
				<StyledCollapse in={isExpanded} collapsedSize={90}>
					{parse(composition)}
					<Box></Box>
				</StyledCollapse>
				<Box onClick={() => handleChange()}>
					{isExpanded ? (
						<StyledToggleCollapseButton>
							{t("common.showLess")}
						</StyledToggleCollapseButton>
					) : (
						<StyledToggleCollapseButton>
							{t("common.showMore")}
						</StyledToggleCollapseButton>
					)}
				</Box>
			</StyledCompositionWrapper>
		</Box>
	);
};

const StyledCollapse = styled(Collapse)(({ theme }) => ({
	"& p, & span": {
		color: `${theme.palette.common.white} !important`, // There might be some styles coming from the DB,
	},
}));
const StyledToggleCollapseButton = styled(
	Typography,
	{},
)(() => ({
	fontWeight: 900,
	marginTop: 8,
	textTransform: "uppercase",
}));

const StyledCompositionWrapper = styled(Box)(() => ({
	"& h5": {
		marginTop: "24px",
	},
	"& table": {
		marginBottom: "32px",
	},
}));
