import React from "react";

import CeleryIcon from "assets/allergens/celery.svg";
import CrustaceansIcon from "assets/allergens/crustaceans.svg";
import EggsIcon from "assets/allergens/eggs.svg";
import FishIcon from "assets/allergens/fish.svg";
import LupinIcon from "assets/allergens/lupin.svg";
import MilkIcon from "assets/allergens/milk.svg";
import MolluscsIcon from "assets/allergens/molluscs.svg";
import MustardIcon from "assets/allergens/mustard.svg";
import NutsIcon from "assets/allergens/nuts.svg";
import PeanutsIcon from "assets/allergens/peanuts.svg";
import SesameIcon from "assets/allergens/sesame.svg";
import SoybeanIcon from "assets/allergens/soybean.svg";
import SpicyIcon from "assets/allergens/spicy.svg";
import SulphurDioxideIcon from "assets/allergens/suplhur_dioxide.svg";
import WheatIcon from "assets/allergens/wheat.svg";
import { AllergenType } from "types";

export const AllergensMapping = [
	{
		name: AllergenType.Gluten,
		icon: <WheatIcon />,
	},
	{
		name: AllergenType.Soybeans,
		icon: <SoybeanIcon />,
	},
	{
		name: AllergenType.Milk,
		icon: <MilkIcon />,
	},
	{
		name: AllergenType.Celery,
		icon: <CeleryIcon />,
	},
	{
		name: AllergenType.Crustaceans,
		icon: <CrustaceansIcon />,
	},
	{
		name: AllergenType.Mustard,
		icon: <MustardIcon />,
	},
	{
		name: AllergenType.Molluscs,
		icon: <MolluscsIcon />,
	},
	{
		name: AllergenType.Fish,
		icon: <FishIcon />,
	},
	{
		name: AllergenType.SulphurDioxide,
		icon: <SulphurDioxideIcon />,
	},
	{
		name: AllergenType.Eggs,
		icon: <EggsIcon />,
	},
	{
		name: AllergenType.Lupin,
		icon: <LupinIcon />,
	},
	{
		name: AllergenType.Peanuts,
		icon: <PeanutsIcon />,
	},
	{
		name: AllergenType.SesameSeeds,
		icon: <SesameIcon />,
	},
	{
		name: AllergenType.Nuts,
		icon: <NutsIcon />,
	},
	{
		name: AllergenType.Spicy,
		icon: <SpicyIcon />,
	},
];
