import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { SwipeableDrawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { SyntheticEvent, useRef } from "react";
import { ORDER_STEP_1_SIZE_LARGE, ORDER_STEP_1_SIZE_SMALL } from "utils/designTokens";

import DrawerActionBar from "components/drawerActionBar";
import { BlurredBackground } from "components/primitives/BlurredBackground";
import { useDrawerContext } from "contexts/DrawerContext";
import { useOrder } from "hooks/useOrder";
import { useOrderSteps } from "hooks/useOrderSteps";

import { getDrawerHeight } from "./utils";

export const OrderProcessDrawer = () => {
	const { orderSummary } = useOrder();
	const { open, toggleDrawer } = useDrawerContext();
	const { currentOrderStep, currentOrderStepScreen } = useOrderSteps({ open, toggleDrawer });
	const actionBarRef = useRef<HTMLDivElement>(null);
	const drawerRef = useRef<HTMLDivElement | null>(null);
	const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const handleToggleDrawer = (
		newState: boolean,
		e?: SyntheticEvent<HTMLElement> | MouseEvent | TouchEvent,
	) => {
		const isTargetHTMLElement = e?.target instanceof HTMLElement;
		const languagePicker = document.getElementById("language-picker");
		if (languagePicker && isTargetHTMLElement && languagePicker.contains(e.target)) {
			return;
		} else {
			toggleDrawer(newState);
		}
	};

	const getCurrentDrawerHeight = () => {
		return getDrawerHeight(open, currentOrderStep, orderSummary);
	};

	return (
		<>
			<ClickAwayListener
				onClickAway={(e) => {
					const languagePicker = document.getElementById("language-picker");
					if (languagePicker && e.target instanceof HTMLElement) {
						if (languagePicker.contains(e.target)) {
							return;
						}
					}

					if (actionBarRef.current?.children) {
						for (const child of actionBarRef.current?.children || []) {
							if (child.contains(e.target as Node)) {
								return;
							}
						}
					}
					if (actionBarRef.current === e.target) {
						return;
					}
					drawerRef.current?.classList.add("test");
					handleToggleDrawer(false, e);
				}}
				touchEvent={"onTouchStart"}
			>
				<div>
					<ProcessDrawer
						ref={drawerRef}
						anchor={"bottom"}
						open={open}
						onClose={(e: SyntheticEvent<HTMLElement>) => handleToggleDrawer(false, e)}
						onOpen={(e: SyntheticEvent<HTMLElement>) => {
							handleToggleDrawer(true, e);
							drawerRef.current?.classList.remove("test");
						}}
						disableBackdropTransition={true}
						swipeAreaWidth={
							currentOrderStep === 1 && open
								? ORDER_STEP_1_SIZE_LARGE
								: ORDER_STEP_1_SIZE_SMALL
						}
						allowSwipeInChildren={true} // Enables to swipe up in Kiosk mode
						disableSwipeToOpen={false}
						ModalProps={{
							disableAutoFocus: false,
							keepMounted: true,
							hideBackdrop: false,
							sx: {
								"& .MuiBackdrop-root": {
									top: `calc(100% - ${getCurrentDrawerHeight()}px)`,
								},
							},
						}}
						slotProps={{
							backdrop: {
								invisible: true,
							},
						}}
						sx={{
							height: getCurrentDrawerHeight(),
							top: `calc(100% - ${getCurrentDrawerHeight()}px)`,
							"& .MuiDrawer-paper": {
								height: getCurrentDrawerHeight(),
							},

							"&.test .swipeable-area": {
								transition: "none  !important",
							},
						}}
						disableDiscovery={iOS}
						disableAutoFocus={true}
						data-testid={"process-drawer"}
					>
						<BlurredBackground
							orderStep={currentOrderStep}
							open={open}
							sx={{
								borderRadius: !open ? 0 : "24px 24px 0 0",
							}}
						/>
						{currentOrderStepScreen}
					</ProcessDrawer>
					<DrawerActionBar currentOrderStep={currentOrderStep} ref={actionBarRef} />
				</div>
			</ClickAwayListener>
		</>
	);
};

const ProcessDrawer = styled(SwipeableDrawer)(() => {
	return {
		bottom: "0px",
		visibility: "visible",
		backgroundColor: "transparent",

		"& .MuiDrawer-paper": {
			padding: "0 40px 0 40px",
			border: 0,
			backgroundColor: "transparent",
		},
	};
});
