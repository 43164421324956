import { ScaleValue, createStitches } from "@stitches/react";

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
	createStitches({
		theme: {
			colors: {
				orange: "#F08100",
				greenDark: "#234328",
				green: "#006A61",
				greenTranslucent: "rgba(0, 106, 97, 0.75)",
				greenLight: "#8BD0BC",
				green200: "#408F88",
				black: "#000000",
				white: "#FFFFFF",
				violet: "#4F2B4D",
				pink: "#CD92A8",
				lightGray: "#F0F0F0",
				red: "#F05657",
				darkGray: "#2F4858",
				yellow: "#FFD666",
				transparent: "rgba(0, 0, 0, 0)",
			},
			space: {
				"0": "0px",
				"1": "1px",
				"2": "2px",
				"3": "4px",
				"4": "8px",
				"5": "12px",
				"6": "16px",
				"7": "20px",
				"8": "24px",
				"9": "28px",
				"10": "32px",
				"11": "36px",
				"12": "42px",
				"13": "48px",
				"14": "64px",
				"15": "96px",
				"16": "120px",
				"17": "150px",
			},
			sizes: {
				actionBarItem: "$space$10",
				actionBar: "$space$14",
			},
			radii: {
				tiny: "4px",
				small: "8px",
				base: "12px",
				medium: "16px",
				large: "32px",
				xlarge: "64px",
			},
			fontSizes: {
				h1: "2.25rem",
				h2: "2rem",
				h3: "1.5rem",
				xLarge: "$fontSizes$h3",
				large: "1.25rem",
				regular: "1rem",
				small: ".875rem",
				tiny: ".75rem",
			},
			fontWeights: {
				regular: 400,
				medium: 500,
				bold: 700,
			},
			fonts: {
				Roboto: "Roboto, apple-system, sans-serif",
			},
			shadows: {
				default: "0 10px 20px rgba(0, 0, 0, .19)",
			},
		},
		utils: {
			m: (value: ScaleValue<"space">) => ({
				marginLeft: value,
				marginRight: value,
				marginTop: value,
				marginBottom: value,
			}),
			mx: (value: ScaleValue<"space">) => ({ marginLeft: value, marginRight: value }),
			my: (value: ScaleValue<"space">) => ({ marginTop: value, marginBottom: value }),
			mt: (value: ScaleValue<"space">) => ({ marginTop: value }),
			mb: (value: ScaleValue<"space">) => ({ marginBottom: value }),
			ml: (value: ScaleValue<"space">) => ({ marginLeft: value }),
			mr: (value: ScaleValue<"space">) => ({ marginRight: value }),
			p: (value: ScaleValue<"space">) => ({
				paddingLeft: value,
				paddingRight: value,
				paddingTop: value,
				paddingBottom: value,
			}),
			px: (value: ScaleValue<"space">) => ({ paddingLeft: value, paddingRight: value }),
			py: (value: ScaleValue<"space">) => ({ paddingTop: value, paddingBottom: value }),
			pt: (value: ScaleValue<"space">) => ({ paddingTop: value }),
			pb: (value: ScaleValue<"space">) => ({ paddingBottom: value }),
			pl: (value: ScaleValue<"space">) => ({ paddingLeft: value }),
			pr: (value: ScaleValue<"space">) => ({ paddingRight: value }),
		},
	});

export const globasStyles = globalCss({
	"*": {
		border: 0,
		padding: 0,
		margin: 0,
		boxSizing: "border-box",
	},
	"@import": [
		"url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap') format('ttf')",
		"url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap') format('ttf')",
	],
	"@font-face": [
		{
			fontFamily: "Roboto",
			src: "url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap') format('ttf')",
		},
		{
			fontFamily: "Montserrat",
			src: "url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap') format('ttf')",
		},
	],
	html: {
		fontFamily: "$Montserrat",
		fontSize: "16px",
	},
	button: {
		all: "unset",
	},
	"h1, h2, h3": {
		fontWeight: 500,
		fontSize: "$regular",
	},
	h1: {
		fontSize: "$h1",
	},
	h2: {
		fontSize: "$h2",
	},
	h3: {
		fontSize: "$h3",
	},
	strong: {
		fontWeight: 500,
	},
});
