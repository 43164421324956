import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const StyledCompositionWrapper = styled(Box)(() => ({
	"& h5": {
		marginTop: "24px",
	},
	"& table": {
		marginBottom: "32px",
	},
}));

export const InstructionsTab = ({ instructions }: { instructions: string }) => {
	const { t } = useTranslation();
	return (
		<Box display={"flex"} justifyContent={"space-between"} paddingTop={2}>
			<StyledCompositionWrapper>
				<Typography variant={"headlineMedium"}>
					{t("dishTypeDetail.instructionsTab.title")}
				</Typography>
				{parse(instructions)}
			</StyledCompositionWrapper>
		</Box>
	);
};
