import { selectDishTypes } from "slices/dishesSlice";

import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LocalDishType } from "types/mainTypes";

export const useDishTypes = (): { data: LocalDishType[] | null; isFridgeEmpty: boolean } => {
	const dishTypes = useAppSelector(selectDishTypes);
	return {
		data: dishTypes,
		isFridgeEmpty: dishTypes ? dishTypes.some((dt) => !dt?.dishes?.length) : true,
	};
};
