import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ModalContentWrapper = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	width: "100%",
	height: "100%",
	borderRadius: "16px",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: theme.spacing(5),
	overflow: "hidden",

	"& .swipeableViews": {
		marginBottom: theme.spacing(4),
	},
}));
