import { styled } from "stitches.config";

export const H1 = styled("h1", {
	fontSize: "$h1",
});

export const H2 = styled("h2", {
	fontSize: "$h2",
});

export const H3 = styled("h3", {
	fontSize: "$h3",
});
