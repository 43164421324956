import { Global } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { initSentry } from "./services/external/sentry";
import EAT_PERFECT_THEME from "./utils/theme";

const enableMocking = async () => {
	if (import.meta.env.VITE_MSW_ENABLED !== "true") {
		return;
	}
	const { worker } = await import("./tests/mocks/browser");
	await worker.start();
	window.MSW = {};
};

initSentry();

enableMocking().then(() => {
	createRoot(document.getElementById("root") as HTMLElement).render(
		<StrictMode>
			<AnalyticsContextProvider>
				<ThemeProvider theme={EAT_PERFECT_THEME}>
					<CssBaseline />
					<Global
						styles={{
							".MuiDrawer-root > .MuiPaper-root": {
								height: `calc(50% - ${320}px)`,
								overflow: "visible",
							},
							body: {
								overflow: "unset",
							},
						}}
					/>
					<App />
				</ThemeProvider>
			</AnalyticsContextProvider>
		</StrictMode>,
	);
});
