import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBottomMessage = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3),
	height: "144px",
	position: "absolute",
	alignItems: "center",
	bottom: "120px",
	width: "calc(100% - 80px)",
	margin: "auto",
	display: "flex",
	backgroundColor: theme.palette.common.white,
	borderRadius: theme.shape.borderRadius * 2,
}));
