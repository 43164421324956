import { useMemo } from "react";
import { getAvailableDishTypeIds } from "utils/utils";

import { OrderProcessDrawer } from "components/appDrawer/OrderProcessDrawer";
import { EmptyFridgeModal } from "components/global/modals/EmptyFridgeModal";
import { MealList } from "components/mealList/MealList";
import { useDishTypes } from "hooks/useDishTypes";
import { useOrder } from "hooks/useOrder";
import { DefaultLayout } from "layouts/DefaultLayout";

export const MealsListPage = () => {
	const { data } = useDishTypes();
	const { order, orderDishes } = useOrder();

	const availableDishTypesIds = useMemo(() => {
		if (!data) {
			return null;
		}
		return getAvailableDishTypeIds(data, orderDishes, order?.state);
	}, [orderDishes, data, order?.state]);

	const hasAvailableDishes = availableDishTypesIds && availableDishTypesIds.length > 0;
	const hasOrderDishes = orderDishes.length > 0;
	const isFridgeEmpty = availableDishTypesIds?.length === 0;

	return (
		<DefaultLayout>
			{data && (hasAvailableDishes || hasOrderDishes) && (
				<>
					{hasAvailableDishes && (
						<MealList
							fridgeDishes={data}
							availableDishTypeIds={availableDishTypesIds}
						/>
					)}
					<OrderProcessDrawer />
				</>
			)}
			{data && isFridgeEmpty && !order && <EmptyFridgeModal />}
		</DefaultLayout>
	);
};
