import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

import { PriceWrapper } from "components/global/components/PriceWrapper";
import { DishItemDisplayType, LocalDishType } from "types/mainTypes";

type DishTypeInfoRowProps = {
	dishType: LocalDishType;
	type: DishItemDisplayType;
	pieces?: number;
};

export function InfoRow({ dishType, type, pieces }: DishTypeInfoRowProps) {
	const { t } = useTranslation();
	const { weight, price } = dishType;

	return (
		<StyledDishTypeInfoRow>
			{price && <PriceWrapper price={price} type={type} />}
			{type === DishItemDisplayType.LIST && weight ? (
				<QuantityWeightText>
					{Intl.NumberFormat("cs-CZ", {
						style: "unit",
						unit: "gram",
					}).format(parseInt(weight))}
				</QuantityWeightText>
			) : (
				<QuantityWeightText>{t("common.pcs", { count: pieces })}</QuantityWeightText>
			)}
		</StyledDishTypeInfoRow>
	);
}

const StyledDishTypeInfoRow = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "baseline",
	width: "100%",
	marginTop: theme.spacing(1),
}));

const QuantityWeightText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.5rem",
	lineHeight: "1.75rem",
	color: theme.palette.common.white,
	marginLeft: "auto",
}));
