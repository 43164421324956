import { Typography } from "@mui/material";
import React from "react";

import { useOrder } from "hooks/useOrder";

export const UserSettingsModal = () => {
	const { order } = useOrder();

	if (!order?.user?.name) {
		return null;
	}
	const shortenUserName = (name: string) => {
		const splitName = name.split(" ");
		if (splitName.length > 1) {
			return `${splitName[0]} ${splitName[1][0]}.`;
		}
		return name;
	};

	// TODO: uncomment once we have use settings ready
	// return (
	// 	<BasicModal
	// 		toggleButton={
	// 			<ActionButton>
	// 				<Typography variant={"button"} color={"white"}>
	// 					{shortenUserName(user.name)}
	// 				</Typography>
	// 			</ActionButton>
	// 		}
	// 		modalContent={
	// 			<Typography variant={"h2"}>
	// 				This is the place where user setting might be visible
	// 			</Typography>
	// 		}
	// 	/>
	// );

	return (
		<Typography variant={"button"} color={"white"}>
			{shortenUserName(order.user.name)}
		</Typography>
	);
};
