import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";

import { MealsListPage, RefillModePage } from "pages";

import { ConnectionErrorModal } from "./components/global/modals/ConnectionErrorModal";

const routes: RouteObject[] = [
	{
		path: "/",
		element: <MealsListPage />,
		errorElement: <ConnectionErrorModal appConnected={false} />,
	},
	{
		path: "/refill",
		element: <RefillModePage />,
		errorElement: <ConnectionErrorModal appConnected={false} />,
	},
];

const router = createBrowserRouter(routes);

export const AppRouter = () => <RouterProvider router={router} />;
