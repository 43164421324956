import { FC, PropsWithChildren } from "react";

import { AppContextProvider } from "contexts";

import { DefaultMain } from "./DefaultLayout";
import { styled } from "stitches.config";

const Main = styled(DefaultMain, {
	backgroundColor: "$yellow",
});

const RefillHeader = styled("span", {
	position: "absolute",
	top: "$3",
	left: "50%",
	transform: "translateX(-50%)",
	textTransform: "uppercase",
	color: "$red",
	fontWeight: 500,
});

export const RefillLayout: FC<PropsWithChildren> = ({ children }) => {
	// const isDevelop = process.env.NODE_ENV === "development";
	return (
		<Main>
			<AppContextProvider>
				<RefillHeader>Režim doplnění</RefillHeader>
				{children}
				{/* {isDevelop && <Debug />}*/}
			</AppContextProvider>
		</Main>
	);
};
