import { Menu } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import CzechIcon from "assets/flags/czech.svg";
import EnglishIcon from "assets/flags/english.svg";
import { ActionButton } from "components/primitives/ActionBarButton";
import { useAppContext } from "contexts/AppContext";

import { LanguagePickerOption } from "./LanguagePickerOption";

const LanguagesConfig = [
	{
		name: "Čeština",
		icon: CzechIcon,
		type: "primary",
	},
	{
		name: "English",
		icon: EnglishIcon,
		type: "secondary",
	},
] as const;

export const LanguagePicker = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { dispatch, selectedLanguage } = useAppContext();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const selectLanguage = (lang: "primary" | "secondary") => {
		if (lang !== selectedLanguage) {
			// i18n is changed based on this value in AppContext
			dispatch({ type: "CHANGE_LANGUAGE", payload: lang });
		}
		handleClose();
	};

	return (
		<>
			<ActionButton onClick={handleClick}>
				{selectedLanguage === "primary" ? <CzechIcon /> : <EnglishIcon />}
			</ActionButton>
			<StyledMenu
				id="language-picker"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "language-picker",
				}}
				hideBackdrop={false}
			>
				{LanguagesConfig.map((lang) => (
					<LanguagePickerOption
						key={lang.name}
						onClick={() => selectLanguage(lang.type)}
						languageName={lang.name}
						languageIcon={lang.icon}
					/>
				))}
			</StyledMenu>
		</>
	);
};

const StyledMenu = styled(Menu)(() => ({
	padding: "10px",
	zIndex: 10000,
	width: "100%",
	"& .MuiBackdrop-root": {
		backgroundColor: "#00000075",
	},
	"& .MuiPaper-root": {
		width: "fit-content",
	},
}));
