import { FC, useEffect, useRef, useState } from "react";

import { Flex } from "components/primitives/Flex";
import { useAppContext } from "contexts";
import { LocalDishType } from "types/mainTypes";

import { Cell, ExpiredCell, SegmentIndicator, SegmentTitle, SegmentWrapper, Table } from "./styled";

type Props = Readonly<{
	title: string;
	segmentCapacity: number;
	stock: LocalDishType[];
}>;

type DishTypes = { id: string; name: string | undefined | null; amountOfExpired: number }[];

const checkIfExpired = (expiration: string | null) => {
	if (expiration) {
		const now = new Date(Date.now()).getTime();
		const dishDate = new Date(expiration).getTime();

		return dishDate <= now;
	} else {
		return false;
	}
};

export const Segment: FC<Props> = ({ title, segmentCapacity, stock }) => {
	const [segmentState, setSegmentState] = useState<"error" | "warning" | "valid">("valid");
	const { current: dishTypes } = useRef<DishTypes>([]);
	const { selectedLanguage } = useAppContext();

	const currentSegmentDishType = stock?.find((dishType) =>
		dishType?.dishes?.some((dish) => dish.position === title),
	);

	const { dishes } = currentSegmentDishType ?? { dishes: [] };

	useEffect(() => {
		if (stock && stock.length < segmentCapacity && segmentState !== "error") {
			setSegmentState("warning");
		}
		if (dishes) {
			dishes.map(({ expiration }) => {
				if (segmentState !== "error") {
					checkIfExpired(expiration)
						? setSegmentState("error")
						: setSegmentState("valid");
				}
			});
		}
	}, [dishes]);

	useEffect(() => {
		dishTypes.length = 0;
		if (dishes) {
			dishes.map((dish) => {
				if (dishTypes.length === 0 || !dishTypes.find(({ id }) => id === dish?.type?.id)) {
					dishTypes.push({
						id: currentSegmentDishType?.id || "",
						name: currentSegmentDishType?.description
							? currentSegmentDishType?.description[selectedLanguage]?.name
							: "",
						amountOfExpired: dishes.filter((dish) => checkIfExpired(dish.expiration))
							.length,
					});
				}
			});
		}
	}, [stock]);

	return (
		<SegmentWrapper state={segmentState}>
			<SegmentTitle>{title}</SegmentTitle>
			<Flex align="center" noWrap justify="between">
				<Table>
					<thead>
						<tr>
							<th>Název položky</th>
							<th>Po expiraci</th>
						</tr>
					</thead>
					<tbody>
						{[...dishTypes.values()].map(({ id, name, amountOfExpired }) => (
							<tr key={id}>
								<Cell>{name}</Cell>
								<ExpiredCell state={amountOfExpired > 0 ? "error" : "valid"}>
									{amountOfExpired}
								</ExpiredCell>
							</tr>
						))}
					</tbody>
				</Table>
				<Flex noShrink direction="column" align="center" css={{ width: "25%" }}>
					<SegmentIndicator state={segmentState}>
						{dishes && dishes.length}/{segmentCapacity}
					</SegmentIndicator>
					<span>Počet jídel</span>
				</Flex>
			</Flex>
		</SegmentWrapper>
	);
};
