import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { Provider } from "react-redux";

import { DeviceContextProvider } from "contexts/DeviceContext";
import { AppRouter } from "router";

import { ConnectionErrorModal } from "./components/global/modals/ConnectionErrorModal";
import { AppContextProvider } from "./contexts";
import "./services/i18n/config";
import { store } from "./store";
import { useFridgeApolloClient } from "./utils/ApolloClientProvider";

export const App = () => {
	const { client, isConnected, isSubscriptionBroken, setIsSubscriptionBroken } =
		useFridgeApolloClient();

	return (
		<SentryErrorBoundary fallback={<ConnectionErrorModal appConnected={false} />}>
			<Provider store={store}>
				<ApolloProvider client={client}>
					<AppContextProvider>
						<DeviceContextProvider
							client={client}
							appConnected={isConnected}
							isSubscriptionBroken={isSubscriptionBroken}
							setIsSubscriptionBroken={setIsSubscriptionBroken}
						>
							<AppRouter />
							{!isConnected && (
								<ConnectionErrorModal
									appConnected={isConnected}
									isSubscriptionBroken={isSubscriptionBroken}
								/>
							)}
						</DeviceContextProvider>
					</AppContextProvider>
				</ApolloProvider>
			</Provider>
		</SentryErrorBoundary>
	);
};
