import { useMutation } from "@apollo/client";
import React, { ReactElement, useState } from "react";
import { SEND_FEEDBACK_MUTATION } from "utils/gqlOperations";

import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { useFridgeInformation } from "hooks/useFridgeInformation";
import { OrderFeedbackRatingType, OrderFeedbackReasonType } from "types";

import { BasicModal } from "./BasicModal";
import { ReportIssueModalContent } from "./modalsContents/ReportIssueModalContent";

type ReportIssueModalProps = {
	toggleButton: ReactElement;
	transactionId: string;
	resetOrder: () => void;
	onClick?: () => void;
};

export const ReportIssueModal = ({
	toggleButton,
	transactionId,
	resetOrder,
	onClick,
}: ReportIssueModalProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { id } = useFridgeInformation();

	const { openSnackbarWithContent } = useSnackbarContext();
	const [sendNegativeFeedbackMutation] = useMutation(SEND_FEEDBACK_MUTATION);

	// We do not really care when the feedback is sent or finished sending,
	// we just go on so next customer can buy food
	// eslint-disable-next-line @typescript-eslint/require-await
	const sendFeedback = async (reason: OrderFeedbackReasonType | null) => {
		openSnackbarWithContent(<SnackbarContent variant={"feedbackNegative"} />);
		if (id && transactionId) {
			sendNegativeFeedbackMutation({
				variables: {
					input: {
						orderId: transactionId,
						type: OrderFeedbackRatingType.NEGATIVE,
						reason: reason,
					},
				},
			});
		}
		resetOrder();
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		sendFeedback(null);
	};

	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<BasicModal
			onClose={() => handleCloseModal()}
			onClick={() => handleClick()}
			toggleButton={toggleButton}
			fullHeight={true}
			closable={true}
			modalContent={
				<ReportIssueModalContent
					onClose={() => handleCloseModal()}
					onSendFeedbackAction={(reason) => sendFeedback(reason)}
				/>
			}
			shouldClose={isOpen}
		/>
	);
};
