import { Flex } from "components/primitives/Flex";

import { styled } from "stitches.config";

export const SegmentsWrapper = styled(Flex, {
	borderRadius: "$base",
	background: "$white",
	padding: "$6 $10 $10",
	color: "$darkGray",
});

export const SegmentWrapper = styled("div", {
	border: "1px solid",
	padding: "$6",
	width: "100%",
	borderRadius: "$base",
	position: "relative",

	variants: {
		state: {
			error: {
				borderColor: "$red",
			},
			warning: {
				borderColor: "$orange",
			},
			valid: {
				borderColor: "$green",
			},
		},
	},

	defaultVariants: {
		state: "valid",
	},
});

export const SegmentTitle = styled("span", {
	fontSize: "$small",
	fontWeight: 500,
	top: "-0.65em",
	left: "50%",
	transform: "translateX(-50%)",
	position: "absolute",
	backgroundColor: "$white",
	px: "$4",
	display: "block",
});

export const SegmentIndicator = styled("div", {
	fontSize: "3rem",
	fontWeight: 700,

	variants: {
		state: {
			error: {
				color: "$red",
			},
			warning: {
				color: "$orange",
			},
			valid: {
				color: "$green",
			},
		},
	},

	defaultVariants: {
		state: "valid",
	},
});

export const Table = styled("table", {
	flexGrow: 1,
	borderCollapse: "collapse",

	th: {
		textAlign: "left",
		fontWeight: 500,

		"&:first-of-type": {
			paddingLeft: "$10",
		},

		"&:last-of-type": {
			textAlign: "center",
		},
	},

	"tr:not(:last-of-type)": {
		borderBottom: "1px solid $lightGray",
	},
});

export const Cell = styled("td", {
	py: "$3",

	"&:first-of-type": {
		width: "85%",
	},

	"&:last-of-type": {
		textAlign: "center",
		width: "15%",
	},
});

export const ExpiredCell = styled(Cell, {
	fontWeight: 500,

	variants: {
		state: {
			error: {
				color: "$red",
			},
			valid: {
				color: "$green",
			},
		},
	},

	defaultVariants: {
		state: "valid",
	},
});
