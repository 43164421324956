import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type PaddedButtonProps = ButtonProps & {
	stretch?: boolean;
};

const PaddedButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "stretch",
})<PaddedButtonProps>(({ stretch, theme }) => ({
	borderRadius: theme.spacing(1),
	backgroundColor: "#FFFFFF26",
	maxWidth: stretch ? "unset" : "64px",
	minWidth: "64px",
	height: "64px",
	// Because of Default active style
	"&:hover": {
		backgroundColor: "#FFFFFF26",
	},
	padding: theme.spacing(1),
}));

export const ActionButton = (props: PaddedButtonProps) => {
	return <PaddedButton {...props} />;
};
