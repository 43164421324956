import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { DrawerSubHeaderText } from "components/primitives/DrawerSubHeaderText";
import { HeaderText } from "components/primitives/HeaderText";

import { PaymentMethodType } from "../../../gql/graphql";
import { Maybe } from "../../../types";

type Order5DrawerHeaderProps = {
	isEmptyOrder: boolean;
	paymentMethod?: Maybe<PaymentMethodType>;
};
export const Order5DrawerHeader = ({ isEmptyOrder, paymentMethod }: Order5DrawerHeaderProps) => {
	const { t } = useTranslation();
	const headerKeyBase = isEmptyOrder ? "emptyOrder" : paymentMethod;

	return (
		<Box sx={{ display: "flex", flexDirection: "column", paddingTop: 2, marginBottom: 2 }}>
			<HeaderText variant={"h3"} hasBorder={true}>
				{t(`order.step5${headerKeyBase ? "." + headerKeyBase : ""}.header` as const)}
			</HeaderText>
			<DrawerSubHeaderText>
				{t(`order.step5${headerKeyBase ? "." + headerKeyBase : ""}.subheader` as const)}
			</DrawerSubHeaderText>
		</Box>
	);
};
